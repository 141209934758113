import {
    Button,
    Form,
    Input,
    DatePicker,
 } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import { subYears } from 'date-fns';
import moment from 'moment';
import React, { useEffect } from 'react'


export default function ScratcherRegister({ setMainLoading }: any) {
    const [form] = Form.useForm()

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
        };
        const tailFormItemLayout = {
        wrapperCol: {
            xs: {
            span: 24,
            offset: 0,
            },
            sm: {
            span: 16,
            offset: 8,
            },
        },
        };


    useEffect(() => {
        setMainLoading(false)
        // // console.log({ Blobs, blobId })
        // const BlobFounded = Blobs[0]
        // if (!BlobFounded || !BlobFounded.enabled) {
        //     message.error('Oops, el bloque consultado no está activo aún, intenta más tarde.')
        //     history.push('/dashboard')
        // } else {
        //     setCurrentBlob(BlobFounded)
        // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onFinish = (values: any) => {
        console.log('Received values of form: ', values);
    };

    
    return (
        <div className="App">
            <div className="Dashboard ScratcherRegister">
                <div className="Wrapper">
                    <Form
      {...formItemLayout}
      form={form}
      name="register"
      onFinish={onFinish}
      initialValues={{
        residence: ['zhejiang', 'hangzhou', 'xihu'],
        prefix: '86',
      }}
      scrollToFirstError
    >
      {['Nombre', 'Apellido(s)', 'CC', 'Género'].map(item => <Form.Item
        name={item}
        label={item}
      >
        <Input />
      </Form.Item>)}

      <Form.Item label="Fecha de nacimiento" name="date" style={{
            
        }}>
            <DatePicker
                locale={locale}
                // onKeyDown={(e) => {
                //     if (e.keyCode === 13 && form.getFieldsValue().date ) {
                //         // @ts-ignore
                //         document.querySelector('#production')?.click()
                //         // @ts-ignore
                //         document.querySelector('#production')?.focus()
                //     }
                // }}
                disabledDate={(d => d.isAfter(subYears(new Date(), 18)))}
                defaultPickerValue={moment(subYears(new Date(), 18))}
                style={{
                    minWidth: 250
                }} />
        </Form.Item>

        <Form.Item label="Fecha de Contrato" name="startDate" style={{
                
            }}>
                <DatePicker
                    locale={locale}
                    // onKeyDown={(e) => {
                    //     if (e.keyCode === 13 && form.getFieldsValue().date ) {
                    //         // @ts-ignore
                    //         document.querySelector('#production')?.click()
                    //         // @ts-ignore
                    //         document.querySelector('#production')?.focus()
                    //     }
                    // }}
                    style={{
                        minWidth: 250
                    }} />
            </Form.Item>

      <Form.Item {...tailFormItemLayout}>
        <Button type="primary" htmlType="submit">
          Añadir rayador
        </Button>
      </Form.Item>
    </Form>
                    
                       
                </div>
            </div>
        </div>
    )
}
