import { Button, Card, Col } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import Leaf from '../../Assets/Leaf'

export default function CardBlob ({ id, name: blockName, description, enabled, setMainLoading }: any) {
    const navigate = useNavigate()
    
    return <Col xs={24} md={4} className='BlobsBigContainer'>
        <Card title={<><Leaf className='IconBlob' /> {blockName}</>} className={`CardBlob CardBlob${enabled ? '--Active' : '--Disabled'}`}>
            <p>{description}</p>
            <Button type="default" block onClick={() => {
                setMainLoading(true)
                setTimeout(() => navigate(`/blob/view/${id}`), 2000)
            }} className='GhostGreenButton'>
                Examinar...
            </Button>
            
        </Card>
    </Col>
}
