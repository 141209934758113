// @ts-nocheck
import React from 'react'
import { Helmet } from 'react-helmet'

import { Provider } from 'react-redux'
import configureStore from './Store'
import { PersistGate } from 'redux-persist/integration/react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import esES from 'antd/lib/locale/es_ES';

import './App.scss'

// Vistas
import {
  Login,
  DashboardIndex,
  ConfigurationIndex,
  BlobView
} from './Views'

// Router
import { PublicRoute, PrivateRoute } from './Routes'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { API_URL } from './Constants'
import BlobRegister from './Views/Blob/Register'
// import firebase from 'firebase'
import moment from 'moment';
import 'moment/locale/es';
// import locale from 'antd/lib/locale/es_ES';
import ScratcherRegister from './Views/Scratcher/Register'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import es from './I18n/es'
import BlobPeople from './Views/Blob/People'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      es
    },
    lng: 'es', // if you're using a language detector, do not define the lng option
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })

moment.locale('es', {
    week: {
        dow: 1
    }
})

const { persistor, store } = configureStore()

const client = new ApolloClient({
  uri: API_URL,
  cache: new InMemoryCache({
    addTypename: false
  })
});


function App() {
  // https://www.freecodecamp.org/news/how-to-add-push-notifications-to-a-web-app-with-firebase-528a702e13e1/
  /* const messaging = firebase.messaging()
  messaging.requestPermission().then(() => {
    messaging.getToken().then(token => {
      console.log({ token })
    })
  })
  */

  return <ApolloProvider client={client}>
    <ConfigProvider locale={esES}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Helmet titleTemplate="Administrador - %s" />
            <Routes>
              {/* <Route path="/secret" element={<PrivateRoute><Login /></PrivateRoute>} /> */}
              <Route path='/' element={<PublicRoute><Login /></PublicRoute>} />              
              <Route path="/dashboard" element={<PrivateRoute><DashboardIndex /></PrivateRoute>} />
              <Route path="/blob/view/:blobId" element={<PrivateRoute><BlobView /></PrivateRoute>} />
              <Route path="/blob/people/:blobId" element={<PrivateRoute><BlobPeople /></PrivateRoute>} />
              <Route path="/blob/register/:typeRegister/:blobId" element={<PrivateRoute><BlobRegister /></PrivateRoute>} />
              <Route path="/parameters/scratcher/new" element={<PrivateRoute><ScratcherRegister /></PrivateRoute>} />
              <Route path="/configuration" element={<PrivateRoute><ConfigurationIndex /></PrivateRoute>} />
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </ConfigProvider>
  </ApolloProvider>
}

export default App