import React from 'react'
import { Tooltip } from 'antd'

export default function AvanceCols ({ data, color }: any) {
    if (!data || !data.graph) return null

    const { total, um, graph } = data
    const [maxValue] = [...graph, total].sort((a: number, b: number) => b - a)
    
    return <div className={`BarChart BarChart--${color}`}>
        {[...graph, total].map(bar => <Tooltip placement="top" title={`${bar} ${um}`}><div className={`BarChartCol`} style={{
            height: `${Math.round(bar / Number(maxValue) * 100)}%`
        }} >{bar}</div></Tooltip>)}
    </div>
}
