/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Row, Space, Steps, Button, InputNumber, Select, Col } from "antd";
import { Calendar, dateFnsLocalizer } from 'react-big-calendar'

import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'

import {
    MinusCircleOutlined,
    PlusOutlined,
    BookOutlined,
    CalendarOutlined,
} from '@ant-design/icons'
import { addDays, setHours } from "date-fns/esm";
import { addMinutes, isWithinInterval, setMinutes } from "date-fns";
import { useNavigate } from "react-router-dom";

const locales = {
    'es': require('date-fns/locale/es'),
}

const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})


export default function Wizard ({ id, name, enabled, tasks, details, insights, createdAt, setRegister, setMainLoading }: any) {
    const [step, setStep] = useState<number>(0)
    const [form] = Form.useForm();
    const [events, setEvents] = useState<any>([])
    const navigate = useNavigate()
    
    const steps = [
        /**
         * Información General
         * 
         * La configuración por tarea del bloque, permitiendo modificar cantidad de árboles
         * edad y tipos de clon(es).
         */
        [<BookOutlined />, 'Información General'],
        // [<SwapOutlined />, 'Configuración del Periodo'],
        [<CalendarOutlined />, 'Propuesta de Rayadas'],
    ]

    if (details) {
        details = details.map((task: any) => {
            if (task.clon && typeof task.clon === 'string') {
                task.clon = [
                    ...task?.clon?.split!(',')
                ]
            }
            return task
        })
    }

    useEffect(() => {
        setRegister({
            disabled: false,
            name: step === 0 ? 'Siguiente' : 'Cerrar',
            next: () => nextStep()
        })
    // eslint-disable-next-line no-sparse-arrays
    }, [,step])

    const nextStep = () => {
        if (!step) {
            const range = {
                start: new Date(2022, 2, 15), // 15 de marzo
                end: new Date(2023, 1, 17) // 15 de febrero
            }

            const blocks = [
                [1, 2, 3, 51, 52, 53, 54, 55, 56, 57, 24, 25, 26, 27, 28, 29, 30, 31, 32],
                [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 33, 34, 35, 36, 37, 38, 39, 40, 41],
                [14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 42, 43, 44, 45, 46, 47, 48, 49, 50]
            ]
            // const mainRange = eachDayOfInterval(range)

            const accum = []
            const colorMap = new Map([
                [0, '#ffc107'],
                [1, '#3f51b5'],
                [2, '#e91e63']
            ])
            let cBlock = 0


            for (const block of blocks) {
                let currentDay = addDays(new Date(2022, 2, 15), cBlock)

                while (isWithinInterval(currentDay, range)) {
                    currentDay = setHours(currentDay, 12)
                    currentDay = setMinutes(currentDay, 0)
                    for (const day of block) {
                        const color = colorMap.get(cBlock)
                        accum.push({
                            id: (accum.length + 1),
                            title: `Tarea ${day}`,
                            start: currentDay,
                            end: addMinutes(currentDay, 60),
                            color
                        })   
                    }
                    currentDay = addDays(currentDay, 3)
                }
                cBlock++
            }

            // calculate dates
            setEvents(accum)
            console.log({
                accum,
                stringify: JSON.stringify(accum)
            })

            setStep(step+1)
        } else {
            console.log('enviando')
            setMainLoading(true)
            setTimeout(() => navigate(`/blob/view/${id}`), 1000)
        }
    }

    const eventStyleGetter = (event: any, start: any, end: any, isSelected: boolean) => {
        return {
            style: {
                backgroundColor: event.color
            }
        }
    }

    return <Form form={form} style={{ width: '100%' }} layout='horizontal' initialValues={{
        details
    }}>
        <Row style={{ width: '100%' }}>
            <Steps size='default'>
                {steps.map(([icon,currStep], index) => {
                    let status: any = 'process'
                    if (step !== index) {
                        status = step > index ? 'finish' : undefined
                    }
                    return <Steps.Step status={status} title={currStep} icon={icon} key={currStep.toString()} />
                })}
            </Steps>
        </Row>
        {step === 0 && <Row style={{ marginTop: 32 }}>
            <Col span={18}>
                <Form.List name="details">
                    {(fields, { add, remove }) => (
                    <>
                        {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                            <Form.Item
                                {...restField}
                                label="Tarea"
                                name={[name, 'id']}
                                fieldKey={[fieldKey, 'id']}
                            >
                                <InputNumber placeholder="Falta ID" disabled={index < details.length} />
                            </Form.Item>

                            <Form.Item
                                {...restField}
                                label="Arboles"
                                name={[name, 'arboles']}
                                fieldKey={[fieldKey, 'arboles']}
                                rules={[{ required: true, message: 'Cant. de árboles' }]}
                            >
                                <InputNumber />
                            </Form.Item>
                            <Form.Item
                                {...restField}
                                label="Edad"
                                name={[name, 'edad']}
                                fieldKey={[fieldKey, 'edad']}
                                rules={[{ required: true, message: 'Edad de la tarea' }]}
                            >
                                <InputNumber min={1} max={99} />
                            </Form.Item>
                            <Form.Item
                                {...restField}
                                label="Clon"
                                name={[name, 'clon']}
                                fieldKey={[fieldKey, 'clon']}
                            >
                                <Select style={{ width: 200 }} mode="multiple">
                                    <Select.Option value="FX 3864">FX 3864</Select.Option>
                                    <Select.Option value="IAN 710">IAN 710</Select.Option>
                                    <Select.Option value="IAN 873">IAN 873</Select.Option>
                                    <Select.Option value="Rimm 600">Rimm 600</Select.Option>
                                    <Select.Option value="GT 1">GT 1</Select.Option>
                                    <Select.Option value="PB 235">PB 235</Select.Option>
                                </Select>
                            </Form.Item>
                            {index >= details.length && <MinusCircleOutlined onClick={() => remove(name)} />}
                        </Space>
                        ))}
                        <Form.Item>
                        <Button type="primary" onClick={() => add()} block icon={<PlusOutlined />}>
                            Agregar tarea en {name}
                        </Button>
                        </Form.Item>
                    </>
                    )}
                </Form.List>
            </Col>
            <Col span={6} style={{ textAlign: 'right', opacity: 0.8 }}>
                <p>Confirmación de las tareas del bloque actual. Es necesario confirmar cada una de las propiedades de las tareas, para proyectar la producción en el periodo <strong>2022 - 2023</strong>.</p>
            </Col>
        </Row>}
        {step === 1 && <>
            <Calendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                popup={true}
                endAccessor="end"
                views={['month', 'agenda']}
                // @ts-ignore
                eventPropGetter={eventStyleGetter}
                style={{ height: 650 }}

                />
        </>}
    </Form>
}
