import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import axios from 'axios'

import { connect, useSelector } from 'react-redux'
import AppFrame from '../Components/AppFrame'

const mapStateToProps = ({ token, isLogged }: any) => {
    return { token, isLogged }
}

// const PrivateRoute = ({ path, component: Component, isLogged, ...rest}: any) => {
const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    const { isLogged } = useSelector((state: any) => state)

    useEffect(() => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        return () => {
            source.cancel()
        }
    }, [])

    return (isLogged ?
        <AppFrame>
            {children}
        </AppFrame>
        : <Navigate to="/" replace />
    )
}

export default connect(mapStateToProps)(PrivateRoute)