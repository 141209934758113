import {
    LOGIN_USER, LOGOUT, BLOCK_NAVIGATION, MODAL_CREATE_TYPE, SET_COUNTRYSIDE
} from "../../Constants"

const initialState: any = {
    token: null,
    isLogged: false,
    serverUrl: 'https://app.bodegaenlinea.cl/api/',
    userInfo: {},
    permissions: [],
    blockNavigation: false,
    modal: {
        resourceType: '',
        missionCampoId: 0,
        positive: true,
        campos: {}
    },
    insights: {
        produccion: {
            total: 0,
            um: 'ton',
            graph: [0,0,0,0],
            budget: 0
        },
        lastDay: {
            total: 0,
            um: 'kg',
            graph: [0,0,0,0],
            budget: 0
        },
        qualityScratch: {
            total: 0,
            um: '/ 10',
            graph: [0,0,0,0],
            budget: 0
        },
        sangriaSeca: {
            total: 0,
            um: 'árboles',
            graph: [0,0,0,0],
            budget: -0
        }
    }
}

const updateState = (state: any, props: any) => Object.assign({}, state, {
    ...props
})


/**
 * Siempre retorna el state completo.
 */
function rootReducer(state = initialState, action: any) {
    switch (action.type) {
        case 'UPDATE_ONE_TASK':
            // Get blobs
            const currentBlobs = state.userInfo.Blobs
            // Get current blob
            const ndxBlob = currentBlobs.findIndex((blob: any) => blob.id === action.payload.blobId)
            // Get current task
            const ndx = currentBlobs[ndxBlob].details.findIndex((b: any) => b.id === action.payload.id)

            // Update
            const newBlobs = [...currentBlobs]
            const apiTask = action.payload
            newBlobs[ndxBlob].details[ndx] = {
                ...newBlobs[ndxBlob].details[ndx],
                rayadorAsignado: apiTask.workerId,
            }

            const newUserInfo = {
                ...state.userInfo,
                Blobs: newBlobs
            }
            return updateState(state, {userInfo: newUserInfo})
        case LOGIN_USER:
            return updateState(state, {
                ...action.payload    
            });
        case LOGOUT:
            return updateState(state, {
                token: null,
                userInfo: null,
                isLogged: false
            })
        case SET_COUNTRYSIDE:
            const userInfo = {
                ...state.userInfo,
                Blobs: action.payload
            }
            return updateState(state, {userInfo})
        case BLOCK_NAVIGATION:
            return updateState(state, {
                blockNavigation: action.payload
            })
        case MODAL_CREATE_TYPE:
            return updateState(state, {
                modal: {
                    ...state.modal,
                    ...action.payload
                }
            })

    }
    return state
}

export default rootReducer