import React, { useEffect } from 'react'

import {
  UserOutlined,
  LogoutOutlined,
  PlusCircleOutlined
} from '@ant-design/icons'
import {
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Menu,
  message,
  Row
} from 'antd'

// Store
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { CREATE_BLOB } from './connection'
import { EDIT_BLOB } from '../Blob/View/connection'
import { BaseViewProps } from '../../Components/AppFrame'

import { useForm } from 'antd/es/form/Form'

function Configuration({ exposeSubMenu, setMainLoading, setPageInfo }: BaseViewProps) {
  const { userInfo } = useSelector((state: any) => state)
  const state: any = useLocation().state

  const navigate = useNavigate()
  /** @todo add token */
  const [save, { data, loading, error }] = useMutation(CREATE_BLOB)
  const [edit, e_EditBlob] = useMutation(EDIT_BLOB)

  const [form] = useForm()

  useEffect(() => {
    if (error) {
      message.error(`Oops, ha ocurrido un error. ${error.message}`)
    }
    if (e_EditBlob.error) {
      message.error(`Oops, ha ocurrido un error. ${e_EditBlob.error.message}`)
    }
  }, [error, e_EditBlob.error])

  useEffect(() => {
    if (data?.CountrysideCreateBlob?.blob) {
      navigate(`/blob/view/${data.CountrysideCreateBlob.blob.id}`)
      console.log(userInfo)
      message.success(`Nuevo bloque creado exitosamente.`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (e_EditBlob.data?.CountrysideEditBlob?.blob) {
      navigate(`/blob/view/${e_EditBlob.data.CountrysideEditBlob.blob.id}`)
      message.success(`Bloque editado creado exitosamente.`)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e_EditBlob.data])
    
  useEffect(() => {
    setMainLoading(loading || e_EditBlob.loading)
  }, [loading,setMainLoading,e_EditBlob.loading])

  useEffect(() => {
    setMainLoading(false)
    if (state) {
      delete state.insights
      delete state.details
      delete state.createdAt
    }

    setPageInfo({
        title: `Creación de nuevo bloque`,
        filter: false,
        menuItems: [
          <Menu.Item onClick={() => {
            form.submit()
          }} icon={<PlusCircleOutlined />}>
              Guardar
          </Menu.Item>
        ]
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!!exposeSubMenu) {
      // const last = { key: 'permissions', name: 'Permisos', icon: <KeyOutlined /> }
      const last = { key: 'index', name: 'Mi perfil', icon: <UserOutlined /> }
      const option = false
        ? [
            last,
            {
              key: 'user:logout',
              name: 'Cerrar Sesión',
              icon: <LogoutOutlined />
            }
          ]
        : [last]
      exposeSubMenu([
        /*{ key: 'usersgroups', name: 'Usuarios y Grupos', icon: <UsergroupAddOutlined /> },*/
        ...option
      ])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exposeSubMenu])

  return (
    <div className='App'>
      <div className='Configuration'>
        <div className='Wrapper'>
          <Col>
            <Row>
              <h2>
                Creación de nuevo Bloque
                <br />
                <small
                  style={{
                    fontWeight: 'normal'
                  }}
                >
                  Para ingresar la información de producción, debes seleccionar
                  la tarea relacionada, la fecha de registro y la cantidad
                  observada (P1).
                </small>
              </h2>
            </Row>
          </Col>
          <Col xs={24} md={23} offset={1}>
            <Card>
              <Row>
                <Form
                  name='basic'
                  labelCol={{ span: 12 }}
                  wrapperCol={{ span: 24 }}
                  initialValues={{ active: true, ...state }}
                  form={form}
                  onFinish={(values) => {
                    if (!state) {
                      save({
                        variables: {
                          CountrysideCreateBlobInput: values
                        }
                      })
                    } else {
                      edit({
                        variables: {
                          CountrysideEditBlobInput: {
                            id: state.id,
                            name: state.name,
                            description: state.description,
                            active: state.active,
                            ...values
                          }
                        }
                      })
                    }
                    console.log({values})
                    // setTimeout(() => navigate(`/dashboard`), 1000)
                  }}
                  onFinishFailed={() => {}}
                  autoComplete='off'
                >
                  <Form.Item
                    label='Nombre del Bloque'
                    name='name'
                    rules={[
                      { required: true, message: 'Por favor, completa el nombre del bloque.' }
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    label='Descripción'
                    name='description'
                  >
                    <Input.TextArea />
                  </Form.Item>

                  <Form.Item
                    name='active'
                    valuePropName='checked'
                    wrapperCol={{ offset: 8, span: 16 }}
                  >
                    <Checkbox>Activo</Checkbox>
                  </Form.Item>
                </Form>
              </Row>
            </Card>
          </Col>
        </div>
      </div>
    </div>
  )
}

export default Configuration
