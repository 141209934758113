import React, { useState } from 'react'
import { SwitchTransition, CSSTransition } from "react-transition-group";

import './index.scss'

import { useNavigate } from 'react-router-dom'

import { Row, Col, Form, Input, Button, message } from 'antd'
import { UserOutlined, LockOutlined, LockFilled } from '@ant-design/icons'

// import { Folders, CreditCard, LogisticaBox, CM } from '../../Assets/'

// import { LoginInfoListItem } from './types'
// import { BOLRouteProps } from '../../Routes/types'

// Store
import { attemptLogin } from '../../Store/Actions'
import { useDispatch } from 'react-redux'
import { useMobile } from '../../Utils'

import iconALNUS from '../../Assets/IconALNUS.svg'
import { useTranslation } from 'react-i18next'
import QuestionCircleOutlined from '@ant-design/icons/lib/icons/QuestionCircleOutlined'

// const mapStateToProps = ({ serverUrl, token }: any) => {
//     return { serverUrl, token }
// }

// const mapDispatchToProps = (dispatch: any) => {
//     return {
//         setToken: (token: string) => dispatch(setToken(token)),
//         attemptLogin: (form: any) => dispatch(attemptLogin(form))
//     }
// }

function Login() {
    const isMobile = useMobile()
    const isDesktop = !isMobile
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()

    const [showingLogin, setShowingLogin] = useState<boolean>(true)

    // const { token } = useSelector((state: any) => state)

    // const { isMobile, serverUrl, token, setToken } = props

    // console.log({ props })
    // console.log({  token })
//   const [token, setToken] = useState(null)
  const [loadingButton, setLoadingButton] = useState(false)

//   useEffect(() => {
//     // cookies.set('Token', token)
//   }, [token])

  const onFinish = async ({ email, password, remember }: any) => {
    setLoadingButton(true)
    
      const { status, ...rest } = await dispatch<Promise<any>>(
          // @ts-ignore
        attemptLogin({
            email,
            password,
            remember
        })
      )
      if (Object.entries(rest).length < 10){
    //   console.log(`Ha ocurrido un error. Reporte este código de error al administrador: ${btoa(err)}`)
        return navigate('/dashboard')
      } else {
          const { error } = rest
          const additionalOopsData = `${error === 'invalid.credentials' ? 'Las credenciales son inválidas.' : 'Los datos ingresados no son correctos.'}`
          message.error(`Oops, ha ocurrido un error: ${additionalOopsData} Revisa los datos e intenta nuevamente.`);

        setLoadingButton(false)
      }
  };

  const onFinishFailed = (errorInfo: any) => {
    setLoadingButton(true)
    console.log('Failed:', errorInfo);
    setLoadingButton(false)
  };

  return (
    <div className="App LoginView">
      <Row className="Header">
        <Col span={12}>
          <img src={iconALNUS} height={64} alt='Logotipo' style={{
                marginRight: 24
              }} />
        </Col>
        <Col span={12} style={{
          textAlign: 'right'
        }}>
          <Button size='large' icon={<QuestionCircleOutlined />}>{t('Help')}</Button>
        </Col>
      </Row>
      
      <Row className="MainContent">
        <SwitchTransition mode={'out-in'}>
          <CSSTransition
            key={`${showingLogin}`}
            addEndListener={(node: any, done: any) => {
              node.addEventListener("transitionend", done, false);
            }}
            classNames="fade">
            <>
            {showingLogin && <Col span={isDesktop ? 6 : 20} offset={isDesktop ? 9 : 2} className="ColumnLogin">
                <div className="Software__Header">
                  <h1>{t('Sign In')}</h1>
                  <h2>{t('powered by ALNUS SpA')}</h2>
                  <p>{t('Complete your login info.')}</p>
                </div>

                <Form
                    name="basic"
                    initialValues={{
                      remember: true,
                    }}
                    autoComplete="off"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                  >

                    <input type="email" name="hidden" id="hidden" style={{
                      width: 0,
                      height: 0,
                      border: 0,
                      padding: 0
                    }} />
                    <input type="password" name="hidden" id="hidden" style={{
                      width: 0,
                      height: 0,
                      border: 0,
                      padding: 0
                    }} />


                  <Form.Item name="email" rules={[ { required: true, message: t('Enter your email address'), }, ]} >
                    <Input size='large' role="presentation" autoComplete="off"  prefix={<UserOutlined className="site-form-item-icon" />}  placeholder={t("Email address")} autoFocus={true} type="email" />
                  </Form.Item>

                  <Form.Item name="password" rules={[ { required: true, message: t('Enter your password'), }, ]} >
                    <Input.Password size='large' role="presentation" autoComplete="off"  prefix={<LockOutlined className="site-form-item-icon" />} placeholder={t("Password")}/>
                  </Form.Item>

                  {/* <Form.Item name="remember" valuePropName="checked" style={{ marginBottom: '8px' }}>
                    <Checkbox>{t('Authorize this device')}</Checkbox>
                  </Form.Item> */}

                  {/* <Form.Item>
                    <a className="login-form-forgot" href="">
                      {t('Lost your password?')}
                    </a>
                  </Form.Item> */}

                  <Form.Item style={{ marginBottom: '0px' }}>
                    <Button type="primary" size='large' htmlType="submit" loading={loadingButton} style={{ width: '100%' }} className="green">
                      {t('Login')}
                    </Button>
                    <Button type="link" size='small' style={{ width: '100%', marginTop: 8 }} className="green" onClick={() => {
                      setShowingLogin(showingLogin => !showingLogin)
                    }}>
                      {t('Lost your password?')}
                    </Button>
                  </Form.Item>

                  <small style={{ fontSize: '9px', textRendering: 'optimizeLegibility' }}>
                    <LockFilled /> {t('The data will be transferred through a secure SSL encrypted connection.')}
                  </small>
                </Form>
            </Col>}
            {!showingLogin && <Col span={isDesktop ? 6 : 20} offset={isDesktop ? 9 : 2} className="ColumnLogin">
                <div className="Software__Header">
                  <h1>{t('Recover your password')}</h1>
                  <h2>{t('powered by ALNUS SpA')}</h2>
                  <p>{t('Complete your email to request a new password')}</p>
                </div>

                <Form
                    name="basic"
                    initialValues={{
                      remember: true,
                    }}
                    autoComplete="off"
                    onFinish={(values) => {
                      console.log({ values })
                    }}
                    onFinishFailed={(errorInfo) => {
                      console.log({ errorInfo })
                    }}
                  >

                    <input type="email" name="hidden" id="hidden" style={{
                      width: 0,
                      height: 0,
                      border: 0,
                      padding: 0
                    }} />


                  <Form.Item name="email" rules={[ { required: true, message: t('Enter your email address'), }, ]} >
                    <Input size='large' role="presentation" autoComplete="off"  prefix={<UserOutlined className="site-form-item-icon" />}  placeholder={t("Email address")} autoFocus={true} type="email" />
                  </Form.Item>


                  <Form.Item style={{ marginBottom: '0px' }}>
                    <Button type="primary" size='large' htmlType="submit" loading={loadingButton} style={{ width: '100%' }} className="green">
                      {t('Recover password')}
                    </Button>
                    <Button type="link" size='small' style={{ width: '100%', marginTop: 8 }} className="green" onClick={() => {
                      setShowingLogin(showingLogin => !showingLogin)
                    }}>
                      {t('Back to login')}
                    </Button>
                  </Form.Item>

                  <small style={{ fontSize: '9px', textRendering: 'optimizeLegibility' }}>
                    <LockFilled /> {t('The data will be transferred through a secure SSL encrypted connection.')}
                  </small>
                </Form>
            </Col>}</>
          </CSSTransition>
        </SwitchTransition>
        
      </Row>

      <Row className="Footer">
        <Col span={12}>{t('Copyright', { year: new Date().getFullYear() })}</Col>
        <Col span={12} style={{ textAlign: 'right' }}>
            <Button target='_new' href={`https://cdn.rubberpl.us/docs/terms-and-conditions.pdf?${new Date().getTime()}`} type="link">{t('Terms')}</Button>
            <Button target='_new' href={`https://cdn.rubberpl.us/docs/privacy-policy.pdf?${new Date().getTime()}`} type="link">{t('Privacy')}</Button>
            <Button target='_new' href={`mailto:rubberplus@alnus.com.co`} type="link">{t('Contact us')}</Button>
        </Col>
      </Row>
    </div>
  )
}

// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(Login)

export default Login