import {
  Avatar,
  Badge,
  Button,
  Card,
  Col,
  Drawer,
  List,
  Menu,
  message,
  Modal,
  Progress,
  Row,
  Statistic,
  Table,
  Select,
  Form,
  Input,
  DatePicker,
} from 'antd'
import React, { useEffect, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import Spreadsheet from "react-spreadsheet";

import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  UserOutlined,
  GoldOutlined,
  CheckOutlined,
  LoginOutlined,
  DownloadOutlined,
  FileExcelFilled,
  EditOutlined,
  UsergroupAddOutlined,
  DeleteOutlined,  
  FolderOpenOutlined,
  FolderOutlined,
  SettingOutlined,
  CalendarOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons'
import AvanceCols from '../../../Components/AvanceCols'

// import { HeatMapGrid } from 'react-grid-heatmap'
// eachDayOfInterval, isDate, differenceInDays
import { format } from 'date-fns'

import moment from 'moment'
import Column from 'antd/lib/table/Column'

import { Line } from '@reactchartjs/react-chart.js'
import { EDIT_BLOB, GET_PRODUCTION_TASK } from './connection'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import exportFromJSON from 'export-from-json'
import { useMobile } from '../../../Utils'
import { ASSIGN_SCRATCHER, GET_SCRATCHER_LIST } from '../People/connection'
import { updateOneTask } from '../../../Store/Actions'
import { BaseViewProps } from '../../../Components/AppFrame'
import { useForm } from 'antd/es/form/Form'

moment.locale('es-es', {
  week: {
    dow: 1
  }
})

interface BlobViewProps extends BaseViewProps {
  
}

function BlobView({ setMainLoading, setPageInfo, filters, period }: BlobViewProps) {
  const {
    userInfo: { Blobs }
  } = useSelector((state: any) => state) //
  const navigate = useNavigate()
  const isMobile = useMobile()
  const dispatch = useDispatch()
  const { blobId } = useParams<any>()
  const [currentBlob, setCurrentBlob] = useState<any>({})
  const [isModal, setModal] = useState<boolean>(false)
  const [modalPeriod, setModalPeriod] = useState<boolean>(false)
  const [form] = useForm()
  // const [detailTask, setDetailTask] = useState<string>('')
  const [selectedTask, setSelectedTask] = useState<any>({})
  const [rayadasTo, setRayadasTo] = useState<number>(0)
  const [produccionTo, setProduccionTo] = useState<number>(0)

  const [rayadores, setRayadores] = useState<any[]>([])

  const [productions, setProductions] = useState<any[]>([])
  
  const [edit, e_EditBlob] = useMutation(EDIT_BLOB)
  const e_ScratcherAvailables = useQuery(GET_SCRATCHER_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache',
    variables: {
      PeopleListScratcherInput: {
          blobId: Number(blobId)
      }
    }
  })
  const [getData, { loading, data }] = useLazyQuery(GET_PRODUCTION_TASK)
  const [getTaskData, dataFromTask] = useLazyQuery(GET_PRODUCTION_TASK)
  const [assignToTask, e_AssignToTask] = useMutation(ASSIGN_SCRATCHER, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })


  useEffect(() => {
    if (e_ScratcherAvailables.data?.PeopleListScratcher?.success && e_ScratcherAvailables.data?.PeopleListScratcher?.scratchers?.length > 0) {
      setRayadores(e_ScratcherAvailables.data.PeopleListScratcher.scratchers)
    }
  }, [e_ScratcherAvailables.data])

  useEffect(() => {
    if (e_EditBlob.data?.CountrysideEditBlob?.blob) {
      message.info(`El bloque ha sido ${e_EditBlob.data?.CountrysideEditBlob?.blob.active ? 'activado' : 'desactivado'}.`)
    }
  }, [e_EditBlob.data])

  useEffect(() => {
    if (e_EditBlob.error) {
      message.error(`Oops, ha ocurrido un error. ${e_EditBlob.error.message}`)
    }
  }, [e_EditBlob.error])

  useEffect(() => {
    if (filters.startDate !== undefined && filters.endDate !== undefined) {
      const startDate = format(filters.startDate, 'yyyy-MM-dd')
      const endDate = format(filters.endDate, 'yyyy-MM-dd')
      getData({
        variables: {
          RIDProductionTaskInput: {
            blobId: Number(blobId),
            startDate,
            endDate
          }
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  useEffect(() => {
    const startDate = format(filters.startDate, 'yyyy-MM-dd')
    const endDate = format(filters.endDate, 'yyyy-MM-dd')

    if (selectedTask?.id) {
      console.log({ selectedTask })
    }
    getTaskData({
      variables: {
        RIDProductionTaskInput: {
          blobId: Number(blobId),
          startDate,
          endDate,
          taskId: selectedTask.id
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTask])

  useEffect(() => {
    const { data: response } = dataFromTask

    if (response?.getProductionTask) {
      const a = response?.getProductionTask.productions
      setDataAxis({
        // eje vertical derecha
        labels: a.map((item: any) => item.date), // identificador de rayada
        datasets: [
          {
            label: 'Ejecutado',
            data: a.map((item: any) => item.inKg),
            fill: false,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
            yAxisID: 'y-axis-1'
          },
          {
            label: 'Proyectado',
            data: a.map(
              (item: any) => Math.round(item.inKGProyectadas / 100) / 10
            ),
            fill: false,
            backgroundColor: 'rgb(54, 162, 235)',
            borderColor: 'rgba(54, 162, 235, 0.2)',
            yAxisID: 'y-axis-1'
          }
        ]
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFromTask.loading, dataFromTask.data])

  useEffect(() => {
    if (!loading && data) {
      setProductions(data.getProductionTask.productions)
      const aha = currentBlob
      // aha.details[0].external = 10
      const prodTo = data.getProductionTask.productions.reduce(
        (acc: any, prod: any) => {
          return acc + prod.inKg
        },
        0
      )
      if (!aha.insights) {
        aha.insights = {}
      }
      if (!aha.insights.produccion) {
        aha.insights.produccion = {}
      }
      aha.insights.produccion.total = prodTo
      setProduccionTo(prodTo)
      const rayTo = data.getProductionTask.productions.reduce(
        (acc: any, prod: any) => {
          return acc + prod.rayadas
        },
        0
      )
      if (!aha.insights.lastDay) {
        aha.insights.lastDay = {}
      }
      aha.insights.lastDay.total = rayTo

      setRayadasTo(rayTo)
      setCurrentBlob(aha)

      // Page setter
      setPageInfo({
        title: `Bloque ${currentBlob?.name} (${currentBlob?.details?.length})`,
        filter: true,
        customRender: (current) => {
          const style: React.CSSProperties = {}
          if (data.getProductionTask.daysWithout.includes(current.format('YYYY-MM-DD'))) {
            style.border = '1px solid #FFCC00'
            style.borderRadius = '50%'
          }
          return <div className='ant-picker-cell-inner' style={style}>{current.date()}</div>
        },
        menuItems: [
          <Menu.Item onClick={goDownloadExcel} icon={<FileExcelFilled style={{color: '#306465' }} />}>
            Exportar como <strong>Excel</strong>
          </Menu.Item>,
          <Menu.SubMenu title="Adm. Periodo..." icon={<CalendarOutlined />}>
            <Menu.Item onClick={() => setModalPeriod(true)} icon={<FolderOpenOutlined />}>
            Apertura de periodo...
            </Menu.Item>
            <Menu.Item danger onClick={showCloseConfirm} icon={<FolderOutlined />}>
              Cierre de periodo...
            </Menu.Item>
          </Menu.SubMenu>,
          <Menu.SubMenu title="Configuración..." icon={<SettingOutlined />}>
            <Menu.Item onClick={() => goLink(`/configuration`, currentBlob)} icon={<UsergroupAddOutlined />}>
              Editar nombre y descripción del bloque...
            </Menu.Item>
            <Menu.Item onClick={() => goLink(`/blob/people/${blobId}`)} icon={<UsergroupAddOutlined />}>
              Administrar rayadores...
            </Menu.Item>
            <Menu.Item onClick={() => goLink(`/blob/register/1-rayada/${blobId}`)} icon={<EditOutlined />}>
              Administrar tareas...
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={showDeleteConfirm} danger={currentBlob.enabled} icon={<DeleteOutlined />}>
              {currentBlob?.enabled ? 'Desactivar' : 'Activar'} bloque <strong>"{currentBlob.name}"</strong>
            </Menu.Item>
          </Menu.SubMenu>
        ]
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data])

  useEffect(() => {
    if (e_AssignToTask.data) {
      dispatch(updateOneTask(Number(blobId), e_AssignToTask.data.PeopleAssignToTask.updatedTask))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e_AssignToTask.data])

  useEffect(() => {
    setMainLoading(loading || dataFromTask.loading || e_EditBlob.loading || e_ScratcherAvailables.loading || e_AssignToTask.loading)
  }, [loading, setMainLoading, dataFromTask.loading, e_EditBlob.loading, e_ScratcherAvailables.loading, e_AssignToTask.loading])

  useEffect(() => {
    setMainLoading(false)
    // console.log({ Blobs, blobId })
    const BlobFounded = Blobs.find((b: any) => b.id === Number(blobId))
    if (!BlobFounded) {
      message.error(
        'Oops, el bloque consultado no está activo aún, intenta más tarde.'
      )
      navigate('/dashboard')
    } else {
      setCurrentBlob(BlobFounded)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [dataAxis, setDataAxis] = useState({
    // eje vertical derecha
    labels: [
      'Rayada 1',
      'Rayada 2',
      'Rayada 3',
      'Rayada 4',
      'Rayada 5',
      'Rayada 6'
    ], // identificador de rayada
    datasets: [
      {
        label: 'Ejecutado',
        data: [0, 0, 0, 0, 0, 0],
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
        yAxisID: 'y-axis-1'
      },
      {
        label: 'Proyectado',
        data: [0, 0, 0, 0, 0, 0],
        fill: false,
        backgroundColor: 'rgb(54, 162, 235)',
        borderColor: 'rgba(54, 162, 235, 0.2)',
        yAxisID: 'y-axis-2'
      }
    ]
  })

  const options = {
    responsive: true,
    tooltips: {
      mode: 'index',
      intersect: true
    },
    scales: {
      yAxes: [
        {
          type: 'linear',
          display: true,
          position: 'left',
          id: 'y-axis-1'
        },
        {
          type: 'linear',
          display: false,
          position: 'right',
          id: 'y-axis-2',
          gridLines: {
            drawOnArea: false
          }
        }
      ]
    }
  }

  const doRegister = (event: any, path: string) => {
    setMainLoading(true)
    setTimeout(() => navigate(`/blob/register/${path}/${blobId}`), 2000)
  }

  const goLink = (path: string, state?: any) => {
    setMainLoading(true)
    setTimeout(() => navigate(`${path}`, state), 1000)
  }

  // const goEditBlob = () => {
  //   setMainLoading(true)
  //   navigate(`/blob/register/1-rayada/${blobId}`)
  // }

  // const goBack = () => {
  //   setMainLoading(true)
  //   navigate(`/dashboard`)
  // }

  const goDownloadExcel = () => {
    setMainLoading(true)
    const excelData = currentBlob?.details?.reduce((acc: any[], detail: any) => {
      const prodData = data.getProductionTask.productions.find(
        (prod: any) => prod.taskId === detail.id
      )
      const _rayador = rayadores.find(rayador => rayador.id === detail?.rayadorAsignado)

      acc.push({
        tareaId: detail.id,
        edad: detail.edad,
        clon: detail.clon,
        rayador: `${_rayador?.name} ${_rayador?.lastName}`,
        rayadasHechas: prodData ? prodData.rayadas : 0,
        rayadasProyectadas: prodData ? prodData.proyectadas : 0,
        produccionHechaKG: prodData ? prodData.inKg : 0,
        produccionProyectadaKG: prodData ? Math.round(prodData.inKGProyectadas / 1000) : 0,
      })
      return acc
    }, [])

    exportFromJSON({
      data: excelData,
      fileName: `${format(
        new Date(),
        'yyyyMMdd'
      )} Detalle de Producción del ${format(
        filters.startDate,
        'dd-MM'
      )} a ${format(filters.endDate, 'dd-MM')}`,
      exportType: 'xls'
    })
    setTimeout(() => {
      setMainLoading(false)
    }, 3000)
  }

  // const goEditPeople = () => {
  //   setMainLoading(true)
  //   navigate()
  // }

  const showCloseConfirm = () => {
    Modal.confirm({
      title: <>¿Estás seguro cerrar el periodo actual en el bloque <strong>{currentBlob.name}</strong>?</>,
      icon: <DeleteOutlined />,
      content: `El cierre de periodo en este bloque no eliminará los datos de producción, pero los rayadores no podrán cargar más rayadas.`,
      okText: 'Sí',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        // goLink('/dashboard')
        // return edit({
        //   variables: {
        //     CountrysideEditBlobInput: {
        //       id: currentBlob.id,
        //       active: !currentBlob.enabled
        //     }
        //   }
        // })
      },
      onCancel() {
        message.info('No se han realizado cambios.');
      },
    });
  }

  const showDeleteConfirm = () => {
    Modal.confirm({
      title: <>¿Estás seguro de {currentBlob.enabled ? 'desactivar' : 'activar'} el bloque <strong>{currentBlob.name}</strong>?</>,
      icon: <DeleteOutlined />,
      content: `${currentBlob.enabled ? 'Desactivar' : 'Activar'} este bloque no eliminará los datos de producción${currentBlob.enabled ? ', pero los rayadores no podrán cargar más rayadas' : ''}.`,
      okText: 'Sí',
      okType: currentBlob.enabled ? 'danger' : 'primary',
      cancelText: 'No',
      onOk() {
        // goLink('/dashboard')
        return edit({
          variables: {
            CountrysideEditBlobInput: {
              id: currentBlob.id,
              active: !currentBlob.enabled
            }
          }
        })
      },
      onCancel() {
        message.info('No se han realizado cambios.');
      },
    });
  }

  useEffect(() => {
    // return () => {
    //   setPageInfo({
    //     title: `Bloque ${currentBlob?.name}`,
    //     filter: false,
    //     customRender: undefined,
    //   })
    // }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  
  const [aperturaStep, setAperturaStep] = useState(1)
  const [takedTasks, setTakedTasks] = useState<any[]>([])
  
  useEffect(() => {
    console.log({ takedTasks })
  }, [takedTasks])

  const [dataSheet, setDataSheet] = useState<any>([
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],
    [{ value: "" }, { value: "" }],
  ]);

  return (
    <div className='App'>
      <div className='Dashboard BlobView'>
        <Modal
        title="Apertura de Periodo"
        visible={modalPeriod}
        className='modalWizard'
        width={1000}
        onOk={async () => {
          if (aperturaStep === 1 && await form.validateFields()) {
            setAperturaStep(2)
          } else {
            form.submit()
          }
        }}
        okText={aperturaStep === 1 ? 'Siguiente' : 'Aceptar'}
        okButtonProps={{
          disabled: aperturaStep === 2 && takedTasks.length !== currentBlob?.details?.length
        }}
        cancelText={aperturaStep === 1 ? 'Cancelar' : 'Atrás'}
        // confirmLoading={e_CreateScratcher.loading}
        cancelButtonProps={{
          // disabled: e_CreateScratcher.loading
        }}
        onCancel={() => {
          if (aperturaStep === 2) {
            setAperturaStep(1)
          } else {
            form.resetFields()
            setModalPeriod(false)
            setAperturaStep(1)
            setTakedTasks([])
          }
        }}
      >
        <Row gutter={[32, 16]}>
          <Col span={8} className='leftWizard'>
            {aperturaStep === 1 && <>
              <h3>Paso 1</h3>
              <p>En primer lugar, completa los siguientes campos para hacer la apertura de un nuevo periodo.</p>
              <br />
              <h4>Fecha de Inicio</h4>
              <p>La fecha de inicio permite realizar el cálculo por grupo de tareas según la frecuencia de rayado, para generar el calendario correspondiente.</p>
              <h4>Tareas excluídas</h4>
              <p>En el campo de Tareas excluídas puedes seleccionar algunas tareas que no formen parte de la apertura del nuevo periodo. Este campo es opcional.</p>
            </>}
            {aperturaStep === 2 && <>
              <h3>Paso 2</h3>
              <p>Finalmente, agrupa las tareas según el primer día de rayado.</p>
              <br />
              <h4>Tareas agrupadas</h4>
              <p>Tienes que crear los grupos de rayada y seleccionar el primer día del rayado, es importante distribuir todas las tareas para aperturar el periodo.</p>
              {(currentBlob?.details?.length ?? 0) - (takedTasks.length) > 0 && <p>Faltan <Badge count={(currentBlob?.details?.length ?? 0) - (takedTasks.length)} />&nbsp; tarea{(currentBlob?.details?.length ?? 0) - (takedTasks.length) > 1 ? 's' : ''} por distribuir.</p>}
            </>}
          </Col>
          <Col span={16}>
            <Form form={form} layout='vertical' onFinish={(values) => {
              console.log(values)
            }} name="nest-messages">
              <div className='Step1' style={{ display: aperturaStep === 1 ? 'block' : 'none' }}>
                {/* <Row gutter={[16, 16]}>
                  <Col span={24}>
                  </Col>
                </Row> */}
                <Row gutter={[16, 16]}>
                  <Col span={10}>
                    <Form.Item name={['period', 'name']} label="Nombre del Periodo" rules={[{ required: true }]}>
                      <Input tabIndex={1} placeholder={`Periodo ${new Date().getFullYear()}`} />
                    </Form.Item>
                    <Form.Item name={['period', 'frecuencyRayado']} label="Frecuencia de Rayado">
                      <Select
                        placeholder="Selecciona una opción"
                        allowClear
                        defaultValue={`Día 3`}
                        tabIndex={6}
                      >
                        <Select.Option value={1}>Día 1</Select.Option>
                        <Select.Option value={2}>Día 2</Select.Option>
                        <Select.Option value={3}>Día 3</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item name={['period', 'startDate']} label="Fecha de Inicio" rules={[{ required: true }]}>
                      <DatePicker tabIndex={2} style={{ minWidth: '100%' }} />
                    </Form.Item>
                    <Form.Item name={['period', 'taskExcluded']} label="Tareas excluídas">
                      <Select
                        placeholder="Selecciona una o más tareas para excluir..."
                        allowClear
                        mode="multiple"
                      >
                        {currentBlob?.details?.map((task: any) => (
                          <Select.Option key={task.id} value={task.id}>Tarea {task.id}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={14}>
                    <Form.Item name={['proyection', 'table']} label="Tabla de Proyección" required rules={[
                      {
                        validator(_) {
                          const emptyValues = dataSheet.flat().filter((row: any) => row.value === '') ?? []
                          if (!emptyValues.length) {
                            return Promise.resolve();
                          }
                          return Promise.reject('Por favor, completa los campos para la tabla de proyección.');
                        },
                      },
                    ]}>
                      <Spreadsheet
                        className='TablaProyeccion'
                        data={dataSheet}
                        columnLabels={['Americano', 'Asiático']}
                        rowLabels={['Año 1', 'Año 2', 'Año 3', 'Año 4', 'Año 5']}
                        onChange={(_data) => {
                          form.setFieldsValue({ proyection: { table: _data } })
                          setDataSheet(_data)
                        }}
                      />
                    </Form.Item>
                  </Col>
                  </Row>
                </div>
                <div className='Step2' style={{ display: aperturaStep === 2 ? 'block' : 'none' }}>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.List name={['period', 'taskGroups']}>
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }) => (
                            // <Space key={key} size='large' style={{ display: 'flex', marginBottom: 8, minWidth: '90%' }} align="baseline">
                            <Row gutter={[16, 16]}>
                              <Col span={10}>
                                <Form.Item
                                  {...restField}
                                  label='Incio de Rayado'
                                  name={[name, 'firstDay']}
                                  rules={[{ required: true, message: 'Falta fecha de inicio' }]}
                                >
                                  <DatePicker  tabIndex={2} style={{ minWidth: '100%' }} />
                                </Form.Item>
                              </Col>
                              <Col span={13}>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'tasks']}
                                  label='Tareas'
                                  rules={[{ required: true, message: 'Es necesaria almenos una tarea' }]}
                                >
                                  <Select
                                    allowClear
                                    mode="multiple"
                                    onSelect={(value: any) => {
                                      setTakedTasks([...takedTasks, {
                                        task: value,
                                        by: name
                                      }])
                                    }}
                                    onDeselect={(value: any) => {
                                      setTakedTasks(takedTasks.filter(task => task.task !== value))
                                    }}
                                  >
                                    {currentBlob?.details?.filter((task: any) => {
                                      // return !fields.some((field: any) => field.name[1].tasks?.some((_task: any) => _task.id === task.id))
                                      const excluded = form.getFieldValue(['period', 'taskExcluded']) ?? []
                                      return !excluded.includes(task.id)
                                    }).map((task: any) => {
                                      // const taskGroups = form.getFieldValue(['period', 'taskGroups']) ?? []
                                      // const taskGroupsWithoutMe = taskGroups.filter((taskGroup: any, localName: number) => name !== localName)
                                      // const takedTasks = taskGroupsWithoutMe.reduce((acc: any, taskGroup: any) => {
                                      //   if (taskGroup?.tasks) {
                                      //     return acc.concat(taskGroup.tasks)
                                      //   }
                                      //   return acc
                                      // }, [])
                                      return <Select.Option disabled={!!takedTasks.find(takedTask => {
                                        return takedTask.task === task.id && takedTask.by !== name
                                      })} key={task.id} value={task.id}>Tarea {task.id}</Select.Option>
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                              <Col span={1} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 16,
                                color: '#ff0000'
                              }}>
                                <MinusCircleOutlined onClick={() => {
                                  setTakedTasks(takedTasks.filter(task => task.by !== name))
                                  return remove(name)
                                }} />
                              </Col>
                              </Row>
                            // </Space>
                          ))}
                          <Form.Item>
                            <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                              Añadir Grupo de Rayada 
                            </Button>
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                    </Col>
                  </Row>
                </div>
              </Form>
          </Col>
        </Row>
      </Modal>
        <div className='Wrapper'>
          {currentBlob?.insights && (
            <>
              <Row gutter={[16, 16]}>
                {/* <Col
                  xs={14}
                  md={12}
                  style={{
                    display: 'flex',
                    flexDirection: 'row'
                  }}
                >
                  <div
                    style={{
                      marginLeft: 16
                    }}
                  >
                    <h1
                      style={{
                        margin: 0,
                        fontWeight: 'bold'
                      }}
                    >
                      {currentBlob.name}
                    </h1>
                    <h4
                      style={{
                        fontWeight: 200,
                        margin: 0
                      }}
                    >
                      Total de tareas activas en este bloque:{' '}
                      <strong>{currentBlob?.details?.length}</strong>
                    </h4>
                  </div>
                </Col>
                <Col
                  xs={10}
                  md={12}
                  className='optionsBtn'
                  style={{
                    textAlign: 'right'
                  }}
                >
                  
                </Col> */}
                <Col xs={12} md={4}>
                  <Card>
                    <Statistic
                      title={
                        <>
                          Tareas activas
                          <h1>
                            <GoldOutlined /> {currentBlob?.details?.length}{' '}
                          </h1>
                        </>
                      }
                      valueStyle={{ display: 'none' }}
                    />
                    <Statistic
                      title={
                        <>
                          Rayadores
                          <h1>
                            <UserOutlined /> {rayadores?.length || 0}{' '}
                          </h1>
                        </>
                      }
                      style={{ marginTop: 16 }}
                      valueStyle={{ display: 'none' }}
                    />
                  </Card>
                </Col>
                <Col xs={12} md={4}>
                  <Card>
                    <Statistic
                      title={
                        <>
                          Número de Rayadas<h1>{rayadasTo} </h1>
                        </>
                      }
                      value={' '}
                      key={rayadasTo}
                      // precision={2}
                      valueStyle={{
                        color:
                          currentBlob?.insights?.produccion?.budget < 0
                            ? '#cf1322'
                            : '#3f8600'
                      }}
                      prefix={
                        <>
                          {currentBlob?.insights?.produccion?.budget < 0 ? (
                            <ArrowDownOutlined />
                          ) : (
                            <ArrowUpOutlined />
                          )}{' '}
                          0 / 0<br />
                          <CheckOutlined className='disabledBlack' />{' '}
                          <span style={{ color: '#000' }}>- por recuperar</span>
                        </>
                      }
                    />
                    <AvanceCols
                      color='yellow'
                      data={currentBlob?.insights.lastDay}
                    />
                    <Button
                      block
                      onClick={(e) => doRegister(e, `1-rayada`)}
                      className='yellowButton'
                      type='primary'
                      style={{ marginTop: 24 }}
                      danger
                    >
                      Leer: <strong>&nbsp;Proyección</strong>
                    </Button>
                  </Card>
                </Col>
                <Col xs={12} md={4}>
                  <Card>
                    <Statistic
                      title={
                        <>
                          Producción
                          <h1>
                            {produccionTo} <small>kg</small>
                          </h1>
                        </>
                      }
                      value={currentBlob?.insights.produccion.budget}
                      precision={2}
                      key={produccionTo}
                      valueStyle={{
                        color:
                          currentBlob?.insights.produccion.budget < 0
                            ? '#cf1322'
                            : '#3f8600'
                      }}
                      prefix={
                        currentBlob?.insights.produccion.budget < 0 ? (
                          <ArrowDownOutlined />
                        ) : (
                          <ArrowUpOutlined />
                        )
                      }
                      suffix={'%'}
                    />
                    <AvanceCols
                      color='green'
                      data={currentBlob?.insights.produccion}
                    />
                    <Button
                      ghost
                      block
                      onClick={(e) => doRegister(e, '2-produccion')}
                      className='yellowButton'
                      type='primary'
                      style={{ marginTop: 46 }}
                      danger
                    >
                      Registrar: <strong>&nbsp;Producción</strong>
                    </Button>
                  </Card>
                </Col>
                <Col xs={12} md={4}>
                  <Card>
                    <Statistic
                      title={
                        <>
                          Calidad de Rayada
                          <h1>
                            {currentBlob?.insights?.qualityScratch?.total}{' '}
                            <small>
                              {currentBlob?.insights?.qualityScratch?.um}
                            </small>
                          </h1>
                        </>
                      }
                      value={currentBlob?.insights?.qualityScratch?.budget}
                      precision={2}
                      valueStyle={{
                        color:
                          currentBlob?.insights?.qualityScratch?.budget < 0
                            ? '#cf1322'
                            : '#3f8600'
                      }}
                      prefix={
                        currentBlob?.insights?.qualityScratch?.budget < 0 ? (
                          <ArrowDownOutlined />
                        ) : (
                          <ArrowUpOutlined />
                        )
                      }
                      suffix={'%'}
                    />
                    <AvanceCols
                      color='blue'
                      data={currentBlob?.insights?.qualityScratch}
                    />
                    <Button
                      ghost
                      block
                      onClick={(e) => doRegister(e, '3-evaluacion')}
                      className='blueButton'
                      type='primary'
                      style={{ marginTop: 46 }}
                      danger
                    >
                      Registrar: <strong>&nbsp;Evaluación</strong>
                    </Button>
                  </Card>
                </Col>
                <Col xs={24} md={4}>
                  <Card>
                    <Statistic
                      title={
                        <>
                          Sangría Seca
                          <h1>
                            {currentBlob?.insights?.sangriaSeca?.total}{' '}
                            <small>
                              {currentBlob?.insights?.sangriaSeca?.um}
                            </small>
                          </h1>
                        </>
                      }
                      value={currentBlob?.insights?.sangriaSeca?.budget}
                      precision={2}
                      valueStyle={{
                        color:
                          currentBlob?.insights?.sangriaSeca?.budget < 0
                            ? '#cf1322'
                            : '#3f8600'
                      }}
                      prefix={
                        currentBlob?.insights?.sangriaSeca?.budget < 0 ? (
                          <ArrowDownOutlined />
                        ) : (
                          <ArrowUpOutlined />
                        )
                      }
                      suffix={'%'}
                    />
                    <AvanceCols
                      color='red'
                      data={currentBlob?.insights?.sangriaSeca}
                    />
                    <Button
                      ghost
                      block
                      onClick={(e) => doRegister(e, '4-sangria-seca')}
                      className='redButton'
                      type='primary'
                      style={{ marginTop: 46 }}
                      danger
                    >
                      Registrar: <strong>&nbsp;Sangría Seca</strong>
                    </Button>
                  </Card>
                </Col>
                <Col xs={14} md={5}>
                  <h1>Rayadores</h1>
                  <h4>Ranking Outliers</h4>
                </Col>
                <Col xs={14} md={12}>
                  <h1>Detalle de Tareas</h1>
                  <h4>Listado individual de tareas</h4>
                </Col>
                <Col xs={10} md={6}>
                  {/* <Radio.Group value={detailTask} onChange={handleDetailTask} size={'large'}>
                                <Radio.Button value="P1">P1</Radio.Button>
                                <Radio.Button value="P2">P2</Radio.Button>
                            </Radio.Group> */}
                </Col>
              </Row>
            </>
          )}
          <Row gutter={[16, 16]} className='TasksDetails'>
            <Col xs={14} md={4}>
              <List
                itemLayout='horizontal'
                dataSource={rayadores}
                renderItem={(item: any) => (
                  <List.Item>
                    <List.Item.Meta
                      // style={{ height: 48, width: 48 }}
                      avatar={
                        <Badge
                          // count={0}
                          dot={!item.Tasks.length}
                          style={{ backgroundColor: item.color }}
                        >
                          <Avatar size={48} shape='square' icon={<UserOutlined />} />
                        </Badge>
                      }
                      title={<a href='/#'>{item.name} {item.lastName}</a>}
                      description={`${item.Tasks.length} tareas asignadas`}
                    />
                  </List.Item>
                )}
              />
            </Col>
            <Col xs={24} md={19}>
              {(!e_ScratcherAvailables.loading && currentBlob?.details) && (
                <Table
                  dataSource={currentBlob.details}
                  pagination={{
                    defaultPageSize: 60,
                    showSizeChanger: false
                  }}
                  style={{ marginTop: 32 }}
                  size='small'
                >
                  <Column title='N°' dataIndex='id' key='id' sorter />
                  <Column title='Edad' dataIndex='edad' key='edad' />
                  <Column title='Clon' dataIndex='clon' key='clon' />
                  <Column
                    title='Árboles'
                    dataIndex='arboles'
                    key='arboles'
                    sorter
                  />
                  <Column
                    title='Rayador/a'
                    dataIndex='rayadorAsignado'
                    key='rayadorAsignado'
                    render={(title, record: any) => {
                      // return <Tag>{title} <EditOutlined color='#234949' /></Tag>
                      const currentRayadorId = Blobs?.find((b: any) => b.id === Number(blobId))?.details?.find((t: any) => t.id === record.id)?.rayadorAsignado
                      const _rayador = rayadores.find(rayador => rayador.id === currentRayadorId)

                      return <Select
                          showSearch
                          // size='small'
                          placeholder="Selecciona rayador/a..."
                          optionFilterProp="children"
                          key={`${record.id}-${currentRayadorId}`}
                          // value={'0'}
                          value={_rayador ? `${_rayador.name} ${_rayador.lastName}` : undefined}
                          /** @todo Disable only current Select */
                          disabled={e_AssignToTask.loading}
                          onChange={(value: string) => {
                            assignToTask({
                              variables: {
                                PeopleAssignToTaskInput: {
                                  blobId: Number(blobId),
                                  taskId: Number(record.id),
                                  scratcherId: Number(value)
                                }
                              }
                            })
                          }}
                          style={{width:'100%'}}
                          // onSearch={onSearch}
                          // filterOption={(input, option) => {
                          //   // return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          //   console.log({ input, option })
                          //   return (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
                          // }}
                        >
                          {rayadores.map(rayador => <Select.Option value={rayador.id}>{rayador.name} {rayador.lastName}</Select.Option>)}
                      </Select>
                    }}
                  />
                  <Column
                    title='Número de Rayados'
                    key='qtyRayados'
                    render={(text, record: any) => {
                      let status:
                        | 'success'
                        | 'normal'
                        | 'exception'
                        | 'active'
                        | undefined = 'success'

                      if (record.id === 17) {
                        console.log(
                          productions.find((re: any) => re.taskId === 17)
                        )
                      }
                      const total = productions
                        .filter((prod) => prod.taskId === record.id)
                        .reduce((acc, prod) => {
                          return acc + prod.rayadas
                        }, 0)

                      const proyectadas = productions
                        .filter((prod) => prod.taskId === record.id)
                        .reduce((acc, prod) => {
                          return acc + prod.proyectadas
                        }, 0)

                      const percent = Math.round((total / proyectadas) * 100)

                      if (percent < 100 && percent >= 90) {
                        status = 'active'
                      }
                      if (percent < 90) {
                        status = 'exception'
                      }

                      // console.log({ percent, taskId: record.id })
                      return (
                        <>
                          <Progress
                            percent={percent}
                            size='small'
                            status={status}
                            strokeColor={percent > 110 ? '#368112' : undefined}
                            format={(percento) => `${percent}%`}
                          />
                          <strong>{total}</strong> / {proyectadas}
                        </>
                      )
                    }}
                  />
                  <Column
                    title='Producción'
                    key='produccion'
                    render={(text, record: any) => {
                      let status:
                        | 'success'
                        | 'normal'
                        | 'exception'
                        | 'active'
                        | undefined = 'success'

                      const total = productions
                        .filter((prod) => prod.taskId === record.id)
                        .reduce((acc, prod) => {
                          return acc + prod.inKg
                        }, 0)

                      const proyectado = Math.round(
                        productions
                          .filter((prod) => prod.taskId === record.id)
                          .reduce((acc, prod) => {
                            return acc + prod.inKGProyectadas
                          }, 0) / 1000
                      )

                      const percent = Math.round((total / proyectado) * 100)

                      if (percent < 100 && percent >= 90) {
                        status = 'active'
                      }
                      if (percent < 90) {
                        status = 'exception'
                      }

                      return (
                        <>
                          <Progress
                            percent={percent}
                            size='small'
                            status={status}
                            strokeColor={percent > 110 ? '#368112' : undefined}
                            format={(percento) => `${percent}%`}
                          />
                          <strong>{total} kg</strong> / {proyectado} kg
                        </>
                      )
                    }}
                  />

                  <Column
                    title='Opciones'
                    width={125}
                    render={(text, record) => {
                      return (
                        <>
                          <Button
                            onClick={() => {
                              setSelectedTask(record)
                              // console.log({ record })
                              setModal(true)
                            }}
                            icon={<LoginOutlined />}
                          >
                            Examinar
                          </Button>
                        </>
                      )
                    }}
                  />
                </Table>
              )}
            </Col>
          </Row>

          {/*<Modal title='Gráfico producción proyectada' visible={isModal} onOk={() => setModal(false)} onCancel={() => setModal(false)}>
                            <Line type='line' data={dataAxis} options={options} />
                        </Modal>*/}

          <Drawer
            title='Previsualización de Ejecutado vs Proyectado'
            width={isMobile ? '100%' : '60%'}
            onClose={() => setModal(false)}
            visible={isModal && !dataFromTask.loading}
            bodyStyle={{ paddingBottom: 80 }}
            maskClosable={false}
            footer={
              <div
                style={{
                  textAlign: 'right'
                }}
              >
                <Button
                  loading={loading}
                  onClick={() => {
                    setMainLoading(true)
                    const excelData =
                      dataFromTask?.data?.getProductionTask?.productions.reduce(
                        (acc: any[], detail: any) => {
                          acc.push({
                            tareaId: detail.taskId,
                            fecha: detail.date,
                            ejecutadoKG: detail.inKg,
                            proyectadoKG: Math.round(
                              detail.inKGProyectadas / 1000
                            )
                          })
                          return acc
                        },
                        []
                      )
                    exportFromJSON({
                      data: excelData,
                      fileName: `${format(
                        new Date(),
                        'yyyyMMdd'
                      )} Ejecutado vs Proyectado Tarea ${
                        excelData[0].tareaId
                      } entre ${format(filters.startDate, 'dd-MM')} y ${format(
                        filters.endDate,
                        'dd-MM'
                      )}`,
                      exportType: 'xls'
                    })
                    setTimeout(() => {
                      setMainLoading(false)
                    }, 2000)
                  }}
                  type='ghost'
                  style={{
                    float: 'left'
                  }}
                  icon={<DownloadOutlined />}
                >
                  Descargar como Excel
                </Button>
                <Button onClick={() => setModal(false)} type='primary'>
                  Volver
                </Button>
              </div>
            }
          >
            <Line
              key={`DrawLine${dataFromTask?.data?.getProductionTask?.productions[0]?.taskId}`}
              type='line'
              data={dataAxis}
              options={options}
            />
          </Drawer>
        </div>
      </div>
    </div>
  )
}

export default BlobView
