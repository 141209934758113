import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

import axios from 'axios'

import { connect, useSelector } from 'react-redux'

const mapStateToProps = ({ isLogged }: any) => {
    return { isLogged }
}

const PublicRoute = ({ children }: { children: JSX.Element }) => {
    const restricted = true
    const { isLogged } = useSelector((state: any) => state)

    useEffect(() => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()

        return () => {
            source.cancel()
        }
    }, [])
    
    return isLogged && restricted ?
        <Navigate to="/dashboard" replace />
    : children
        
}

export default connect(mapStateToProps)(PublicRoute)