// import axios from 'axios'

import { LOGIN_USER, SET_COUNTRYSIDE } from '../../Constants'

export function updateOneTask(blobId: number, task: any) {
    task.blobId = blobId
    return { type: 'UPDATE_ONE_TASK', payload: task }
}

export function updateCountryside({ Blobs }: any) {
  return async (dispatch: any) => {
    
    for (const _blob of Blobs) {
        _blob.insights = {
            produccion: {
                total: 0,
                um: 'ton',
                graph: [0, 0, 0, 0, 0],
                budget: 0
            },
            lastDay: {
                total: 0,
                um: 'kg',
                graph: [0, 0, 0, 0, 0],
                budget: 0
            },
            qualityScratch: {
                total: 0,
                um: '/ 10',
                graph: [0, 0, 0, 0, 0],
                budget: 0
            },
            sangriaSeca: {
                total: 0,
                um: 'árboles',
                graph: [0, 0, 0, 0, 0],
                budget: 0
            }
        }
        _blob.details = _blob.details.reduce((acc: unknown[], task: any) => {
          acc.push({
            id: task.internalId,
            lote: task.lote,
            bloque: _blob.name,
            arboles: task.trees,
            edad: task.age,
            frecuenciaRayado: task.stripeFrecuency,
            rayadorAsignado: task.workerId,
            clon: task.clonType,
            tipoRayado: '1/2',
            createdAt: task.createdAt,
            apiId: task.id
          })
          return acc
        }, [])
    }

    dispatch({
      type: SET_COUNTRYSIDE,
      payload: Blobs
    })
    return Blobs
  }
}

export function attemptLogin({ email: mail, password }: any) {
  return async (dispatch: any) => {
    const fakeUser = 'agro@alnus.io'
    const fakePassword = 'juanca2021'

    const validLogin = fakeUser === mail && fakePassword === password
    if (validLogin) {
      const userInfo = {
        firstName: `Administrador SOCCA`,
        state: 'OK_LOGIN',
        success: true,
        text: 'Inicio correcto, token generado.',
        token: 'Fake_Token_Mockup',
        GroupInfo: {
          id: 0,
          internalName: 'SOCCA',
          name: 'SOCCA',
          userGroupId: 0
        },
        Blobs: [
          {
            id: 1,
            name: 'Bárbol',
            createdAt: new Date('2020-11-18T00:00:00Z'),
            enabled: true,
            tasks: 30,
            insights: {
              produccion: {
                total: 0,
                um: 'ton',
                graph: [0, 0, 0, 0, 0],
                budget: 0
              },
              lastDay: {
                total: 0,
                um: 'kg',
                graph: [0, 0, 0, 0, 0],
                budget: 0
              },
              qualityScratch: {
                total: 0,
                um: '/ 10',
                graph: [0, 0, 0, 0, 0],
                budget: 0
              },
              sangriaSeca: {
                total: 0,
                um: 'árboles',
                graph: [0, 0, 0, 0, 0],
                budget: 0
              }
            },
            details: [
              {
                id: Number('1'),
                lote: 'L1 SA',
                bloque: 'Bárbol',
                arboles: Number('755'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Carolina Morales ',
                clon: 'FX 3864',
                tipoRayado: '1/2'
              },
              {
                id: Number('2'),
                lote: 'L1 SA',
                bloque: 'Bárbol',
                arboles: Number('770'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Carolina Morales ',
                clon: 'FX 3864',
                tipoRayado: '1/2'
              },
              {
                id: Number('3'),
                lote: 'L1 SB',
                bloque: 'Bárbol',
                arboles: Number('749'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Carolina Morales ',
                clon: 'IAN 710',
                tipoRayado: '1/2'
              },
              {
                id: Number('4'),
                lote: 'L1 SB',
                bloque: 'Bárbol',
                arboles: Number('755'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Rubiela Meneses',
                clon: 'FX 3864',
                tipoRayado: '1/2'
              },
              {
                id: Number('5'),
                lote: 'L1 SC',
                bloque: 'Bárbol',
                arboles: Number('778'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Jose Caballero',
                clon: 'IAN 710',
                tipoRayado: '1/2'
              },
              {
                id: Number('6'),
                lote: 'L1 SD',
                bloque: 'Bárbol',
                arboles: Number('765'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Alvaro Traslaviña',
                clon: 'IAN 873',
                tipoRayado: '1/2'
              },
              {
                id: Number('7'),
                lote: 'L1 SD',
                bloque: 'Bárbol',
                arboles: Number('768'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Laura Patricia Montoya',
                clon: 'IAN 873',
                tipoRayado: '1/2'
              },
              {
                id: Number('8'),
                lote: 'L1 SE',
                bloque: 'Bárbol',
                arboles: Number('750'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Yurley Gomez ',
                clon: 'FX 3864',
                tipoRayado: '1/2'
              },
              {
                id: Number('9'),
                lote: 'L2 SA',
                bloque: 'Bárbol',
                arboles: Number('750'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Carmelina Peña ',
                clon: 'FX 3864',
                tipoRayado: '1/2'
              },
              {
                id: Number('10'),
                lote: 'L1 SC',
                bloque: 'Bárbol',
                arboles: Number('743'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Alvaro Traslaviña',
                clon: 'IAN 710',
                tipoRayado: '1/2'
              },
              {
                id: Number('11'),
                lote: 'L1 SD',
                bloque: 'Bárbol',
                arboles: Number('753'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Rubiela Meneses',
                clon: 'IAN 710',
                tipoRayado: '1/2'
              },
              {
                id: Number('12'),
                lote: 'L1 SE',
                bloque: 'Bárbol',
                arboles: Number('754'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Laura Patricia Montoya',
                clon: 'IAN 873',
                tipoRayado: '1/2'
              },
              {
                id: Number('13'),
                lote: 'L2 SA',
                bloque: 'Bárbol',
                arboles: Number('765'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Carmelina Peña ',
                clon: 'FX 3864',
                tipoRayado: '1/2'
              },
              {
                id: Number('14'),
                lote: 'L2 SB',
                bloque: 'Bárbol',
                arboles: Number('760'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Rubiela Meneses',
                clon: 'FX 3864',
                tipoRayado: '1/2'
              },
              {
                id: Number('15'),
                lote: 'L2 SB',
                bloque: 'Bárbol',
                arboles: Number('753'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Alvaro Traslaviña',
                clon: 'FX 3864',
                tipoRayado: '1/2'
              },
              {
                id: Number('16'),
                lote: 'L2 SC',
                bloque: 'Bárbol',
                arboles: Number('757'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Rubiela Meneses',
                clon: 'IAN 710',
                tipoRayado: '1/2'
              },
              {
                id: Number('17'),
                lote: 'L2 SC',
                bloque: 'Bárbol',
                arboles: Number('757'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Laura Patricia Montoya',
                clon: 'IAN 710',
                tipoRayado: '1/2'
              },
              {
                id: Number('18'),
                lote: 'L2 SD',
                bloque: 'Bárbol',
                arboles: Number('750'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Carmelina Peña ',
                clon: 'IAN 873',
                tipoRayado: '1/2'
              },
              {
                id: Number('19'),
                lote: 'L2SD/L3SB',
                bloque: 'Bárbol',
                arboles: Number('715'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Rubiela Meneses',
                clon: 'IAN 873',
                tipoRayado: '1/2'
              },
              {
                id: Number('20'),
                lote: 'L3 SA',
                bloque: 'Bárbol',
                arboles: Number('750'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Jose Caballero',
                clon: 'IAN 873',
                tipoRayado: '1/2'
              },
              {
                id: Number('21'),
                lote: 'L3SA/SB',
                bloque: 'Bárbol',
                arboles: Number('711'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Alvaro Traslaviña',
                clon: 'IAN 710',
                tipoRayado: '1/2'
              },
              {
                id: Number('22'),
                lote: 'L3 SB',
                bloque: 'Bárbol',
                arboles: Number('740'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'carmelina peña ',
                clon: 'FX 3864',
                tipoRayado: '1/2'
              },
              {
                id: Number('23'),
                lote: 'L3 SC',
                bloque: 'Bárbol',
                arboles: Number('753'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Juan Bohorquez',
                clon: 'FX 3864',
                tipoRayado: '1/2'
              },
              {
                id: Number('24'),
                lote: 'L3 SD',
                bloque: 'Bárbol',
                arboles: Number('735'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Lucia Rodriguez',
                clon: 'FX 3864',
                tipoRayado: '1/2'
              },
              {
                id: Number('25'),
                lote: 'L3 SD',
                bloque: 'Bárbol',
                arboles: Number('761'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Jose Caballero',
                clon: 'IAN 710',
                tipoRayado: '1/2'
              },
              {
                id: Number('26'),
                lote: 'L3 SE',
                bloque: 'Bárbol',
                arboles: Number('751'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Laura Patricia Montoya',
                clon: 'IAN 710',
                tipoRayado: '1/2'
              },
              {
                id: Number('27'),
                lote: 'L3 SE',
                bloque: 'Bárbol',
                arboles: Number('751'),
                edad: Number('3'),
                frecuenciaRayado: Number('3'),
                rayadorAsignado: 'Carmelina Peña ',
                clon: 'IAN 873, FX 3864',
                tipoRayado: '1/2'
              },
              {
                id: 28,
                lote: '',
                bloque: 'Bárbol',
                arboles: 748,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 29,
                lote: '',
                bloque: 'Bárbol',
                arboles: 748,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 30,
                lote: '',
                bloque: 'Bárbol',
                arboles: 750,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 31,
                lote: '',
                bloque: 'Bárbol',
                arboles: 763,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 32,
                lote: '',
                bloque: 'Bárbol',
                arboles: 751,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 33,
                lote: '',
                bloque: 'Bárbol',
                arboles: 747,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 34,
                lote: '',
                bloque: 'Bárbol',
                arboles: 752,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 35,
                lote: '',
                bloque: 'Bárbol',
                arboles: 748,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 36,
                lote: '',
                bloque: 'Bárbol',
                arboles: 760,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 37,
                lote: '',
                bloque: 'Bárbol',
                arboles: 767,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 38,
                lote: '',
                bloque: 'Bárbol',
                arboles: 768,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 39,
                lote: '',
                bloque: 'Bárbol',
                arboles: 748,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 40,
                lote: '',
                bloque: 'Bárbol',
                arboles: 739,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 41,
                lote: '',
                bloque: 'Bárbol',
                arboles: 754,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 42,
                lote: '',
                bloque: 'Bárbol',
                arboles: 701,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 43,
                lote: '',
                bloque: 'Bárbol',
                arboles: 768,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 44,
                lote: '',
                bloque: 'Bárbol',
                arboles: 742,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 45,
                lote: '',
                bloque: 'Bárbol',
                arboles: 751,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 46,
                lote: '',
                bloque: 'Bárbol',
                arboles: 748,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 47,
                lote: '',
                bloque: 'Bárbol',
                arboles: 747,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 48,
                lote: '',
                bloque: 'Bárbol',
                arboles: 725,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 49,
                lote: '',
                bloque: 'Bárbol',
                arboles: 703,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 50,
                lote: '',
                bloque: 'Bárbol',
                arboles: 694,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 51,
                lote: '',
                bloque: 'Bárbol',
                arboles: 744,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 52,
                lote: '',
                bloque: 'Bárbol',
                arboles: 758,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 53,
                lote: '',
                bloque: 'Bárbol',
                arboles: 770,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 54,
                lote: '',
                bloque: 'Bárbol',
                arboles: 760,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 55,
                lote: '',
                bloque: 'Bárbol',
                arboles: 762,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 56,
                lote: '',
                bloque: 'Bárbol',
                arboles: 755,
                edad: 1,
                frecuenciaRayado: Number(3),
                rayadorAsignado: '',
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 57,
                arboles: 765,
                rayadorAsignado: '',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 58,
                arboles: 749,
                rayadorAsignado: 'JONATHAN CELIS',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 59,
                arboles: 765,
                rayadorAsignado: 'NURY FERNANDA ROMER',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 60,
                arboles: 760,
                rayadorAsignado: 'SANTIAGO PARRA',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 61,
                arboles: 750,
                rayadorAsignado: 'YURLEY ANDREA GOMEZ AMADO',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 62,
                arboles: 750,
                rayadorAsignado: 'JESISCA PAOLA TAMAYO',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 63,
                arboles: 754,
                rayadorAsignado: 'HENRY CANO BEDOYA',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 64,
                arboles: 656,
                rayadorAsignado: 'YENIFER OLIVERA MORENO',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 65,
                arboles: 666,
                rayadorAsignado: 'YERLINTH SANCHEZ',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 66,
                arboles: 655,
                rayadorAsignado: 'HERMINSON CARREÑO',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 67,
                arboles: 655,
                rayadorAsignado: 'RUBY JUDITH BAYONA',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 68,
                arboles: 654,
                rayadorAsignado: 'BAYRON VELASQUEZ',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 69,
                arboles: 671,
                rayadorAsignado: 'HENRY CANO BEDOYA',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 70,
                arboles: 644,
                rayadorAsignado: 'YENIFER OLIVERA MORENO',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 71,
                arboles: 663,
                rayadorAsignado: 'YERLINTH SANCHEZ',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 72,
                arboles: 649,
                rayadorAsignado: 'HERMINSON CARREÑO',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 73,
                arboles: 607,
                rayadorAsignado: 'RUBY JUDITH BAYONA',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 74,
                arboles: 607,
                rayadorAsignado: 'BAYRON VELASQUEZ',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 75,
                arboles: 594,
                rayadorAsignado: 'HENRY CANO BEDOYA',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 76,
                arboles: 668,
                rayadorAsignado: 'LUIS CARLOS ALVARADO',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 77,
                arboles: 603,
                rayadorAsignado: 'MIREYA ASCANIO ALVARADO',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 78,
                arboles: 700,
                rayadorAsignado: 'TATIANA LEON',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              },
              {
                id: 79,
                arboles: 467,
                rayadorAsignado: 'WILSON MONCADA',
                lote: '',
                bloque: 'Bárbol',
                edad: 1,
                frecuenciaRayado: Number(3),
                clon: 'RIMM 600',
                tipoRayado: '1/2'
              }
            ]
          },
          {
            id: 2,
            name: 'Guma',
            createdAt: new Date('2021-02-18T00:00:00Z'),
            enabled: false,
            tasks: 0
          },
          {
            id: 3,
            name: 'Origen G',
            createdAt: new Date('2021-02-18T00:00:00Z'),
            enabled: false,
            tasks: 0
          },
          {
            id: 4,
            name: 'Origen P',
            createdAt: new Date('2021-02-18T00:00:00Z'),
            enabled: false,
            tasks: 0
          },
          {
            id: 5,
            name: 'Origen FK',
            createdAt: new Date('2021-02-18T00:00:00Z'),
            enabled: true,
            tasks: 24,
            details: [
              {
                id: 1,
                arboles: 656,
                edad: 7,
                clon: 'FX 3864-7',
                rayadorAsignado: 'JESSICA BENITEZ',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 2,
                arboles: 636,
                edad: 7,
                clon: 'FX 3864-7',
                rayadorAsignado: 'ESENEN CAMARGO',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 3,
                arboles: 639,
                edad: 7,
                clon: 'FX 3864-7',
                rayadorAsignado: 'JESUS MORENO',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 4,
                arboles: 640,
                edad: 6,
                clon: 'FX 3864-6',
                rayadorAsignado: 'YOLANDA MORENO',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 5,
                arboles: 620,
                edad: 6,
                clon: 'FX 3864-6',
                rayadorAsignado: 'JOHANA MORA ALVARADO',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 6,
                arboles: 650,
                edad: 6,
                clon: 'FX 3864-6',
                rayadorAsignado: 'FABIÁN ANDRES CASTAÑEDA',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 7,
                arboles: 670,
                edad: 4,
                clon: 'FX 3864-4',
                rayadorAsignado: 'YURI RUBIO',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 18,
                arboles: 380,
                edad: 4,
                clon: 'FX 3864-4',
                rayadorAsignado: 'WILLIAM MEJIA ',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 8,
                arboles: 650,
                edad: 4,
                clon: 'FX 3864-4',
                rayadorAsignado: 'ESENEN CAMARGO',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 9,
                arboles: 620,
                edad: 4,
                clon: 'FX 3864-4',
                rayadorAsignado: 'JESUS MORENO',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 10,
                arboles: 630,
                edad: 4,
                clon: 'FX 3864-4',
                rayadorAsignado: 'JESSICA BENITEZ',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 11,
                arboles: 650,
                edad: 4,
                clon: 'FX 3864-4',
                rayadorAsignado: 'YOLANDA MORENO',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 12,
                arboles: 590,
                edad: 4,
                clon: 'FX 3864-4',
                rayadorAsignado: 'YURI RUBIO',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 27,
                arboles: 640,
                edad: 4,
                clon: 'FX 3864-4',
                rayadorAsignado: 'WILLIAM MEJIA ',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 28,
                arboles: 630,
                edad: 4,
                clon: 'FX 3864-4',
                rayadorAsignado: 'FABIAN ANDRES CASTAÑEDA',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 30,
                arboles: 570,
                edad: 3,
                clon: 'FX 3864-3',
                rayadorAsignado: 'JOHANA MORA ALVARADO',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 13,
                arboles: 590,
                edad: 4,
                clon: 'FX 3864-4',
                rayadorAsignado: 'JOHANA MORA ALVARADO',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 14,
                arboles: 600,
                edad: 4,
                clon: 'FX 3864-4',
                rayadorAsignado: 'ESENEN CAMARGO',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 15,
                arboles: 620,
                edad: 4,
                clon: 'FX 3864-4',
                rayadorAsignado: 'YURI RUBIO',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 16,
                arboles: 630,
                edad: 4,
                clon: 'FX 3864-4',
                rayadorAsignado: 'JESSICA BENITEZ',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 17,
                arboles: 620,
                edad: 4,
                clon: 'FX 3864-4',
                rayadorAsignado: 'N/A',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 34,
                arboles: 680,
                edad: 2,
                clon: 'FX 3864-2',
                rayadorAsignado: 'YOLANDA MORENO',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 35,
                arboles: 630,
                edad: 2,
                clon: 'FX 3864-2',
                rayadorAsignado: 'WILLIAM MEJIA ',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 36,
                arboles: 640,
                edad: 2,
                clon: 'FX 3864-2',
                rayadorAsignado: 'FABIAN ANDRES CASTAÑEDA ',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 37,
                arboles: 663,
                rayadorAsignado: 'CAMILO VELASQUEZ ',
                edad: 1,
                clon: 'RIM 600',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 38,
                arboles: 652,
                rayadorAsignado: 'SIN RAYADOR ',
                edad: 1,
                clon: 'RIM 600',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 39,
                arboles: 656,
                rayadorAsignado: 'CAMILO VELASQUEZ ',
                edad: 1,
                clon: 'RIM 600',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 40,
                arboles: 656,
                rayadorAsignado: 'SIN RAYADOR ',
                edad: 1,
                clon: 'RIM 600',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 41,
                arboles: 587,
                rayadorAsignado: 'ESENEN CAMARGO',
                edad: 1,
                clon: 'RIM 600',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              },
              {
                id: 42,
                arboles: 600,
                rayadorAsignado: 'ESENEN CAMARGO',
                edad: 1,
                clon: 'RIM 600',
                lote: '',
                bloque: 'Origen',
                frecuenciaRayado: 3,
                tipoRayado: '1/2'
              }
            ].sort((a: any, b: any) => {
              return a.id > b.id ? 1 : -1
            }),
            insights: {
              produccion: {
                total: 0,
                um: 'ton',
                graph: [0, 0, 0, 0, 0],
                budget: 0
              },
              lastDay: {
                total: 0,
                um: 'kg',
                graph: [0, 0, 0, 0, 0],
                budget: 0
              },
              qualityScratch: {
                total: 0,
                um: '/ 10',
                graph: [0, 0, 0, 0, 0],
                budget: 0
              },
              sangriaSeca: {
                total: 0,
                um: 'árboles',
                graph: [0, 0, 0, 0, 0],
                budget: 0
              }
            }
          }
        ]
      }

      /*const permissions = response.data.user.permissionInfo.reduce((acc: string[], perm: any) => {
                acc.push(perm.permission)
                return acc
            }, [])*/

      dispatch({
        type: LOGIN_USER,
        payload: {
          token: userInfo.token,
          userInfo,
          permissions: [],
          isLogged: true
        }
      })
      return userInfo
    } else {
      dispatch({
        type: LOGIN_USER,
        payload: {
          token: null,
          userInfo: null,
          isLogged: false
        }
      })
      return 'El usuario y/o clave ingresado, no son válidos.'
    }
  }
}

export function logout(payload: any) {
  return { type: 'LOGOUT', payload }
}

export function setNavigation(payload: boolean) {
  return { type: 'BLOCK_NAVIGATION', payload }
}

export function setModal(payload: any) {
  return { type: 'MODAL_CREATE_TYPE', payload }
}
