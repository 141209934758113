import React, { useEffect, useState } from 'react'

import { 
    HomeOutlined
} from '@ant-design/icons';

import {
    Row,
    Col,
    Card,
    Statistic,
    Checkbox,
    Menu
} from 'antd';

import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    CheckOutlined,
    PlusCircleOutlined
} from '@ant-design/icons'

import './Dashboard.scss'
import { useDispatch, useSelector } from 'react-redux';

import AvanceCols from '../../Components/AvanceCols';
import CardBlob from '../../Components/CardBlob';

import moment from 'moment';
import 'moment/locale/es';
import { useNavigate } from 'react-router-dom';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { useQuery } from '@apollo/client';
import { CS_VIEW } from './connection';
import { updateCountryside } from '../../Store/Actions';
import { BaseViewProps } from '../../Components/AppFrame';

moment.locale('es', {
    week: {
        dow: 1
    }
})

// import { format, subDays } from 'date-fns';
// import { es } from 'date-fns/locale'
// import { useTranslation } from 'react-i18next';
// import StarOutlined from '@ant-design/icons/lib/icons/StarOutlined';
// import { SquareIcon } from '../../Components/SquareIcon/SquareIcon';

// const FrequentlyActions = ({ insights }: any) => {
//     // const [frequentlyActions, setFrequentlyActions] = useState([]);
//     const { t } = useTranslation()

//     return <Card className="FrequentlyActions">
//         <h2 style={{
//             display: 'flex'
//         }}>
//             <SquareIcon dark icon={<StarOutlined />} />
//             <div style={{
//                 lineHeight: '20px', marginTop: '4px', marginLeft: '8px'
//             }}>
//                 {t('Shortcuts')}<br />
//                 <small style={{
//                     fontSize: '14px',
//                     fontWeight: 300
//                 }}>{t('Configure your shortcuts')}</small>
//             </div>
//         </h2>
        
//         <List
//             itemLayout="horizontal"
//             dataSource={[
//             {
//                 title: 'Reporte Mensual Excel',
//                 href: '#',
//                 date: subDays(new Date(), 4 * 100)
//             },
//             {
//                 title: 'Mantenedor de Rayadores',
//                 href: '#',
//                 date: subDays(new Date(), 4 * 100)
//             },
//             {
//                 title: 'Producción en Bárbol',
//                 href: '#',
//                 date: subDays(new Date(), 4 * 100)
//             }
//             ].sort((a, b) => b.date.getTime() - a.date.getTime())}
//             renderItem={item => (
//             <List.Item>
//                 <List.Item.Meta
//                     title={<a href={item.href}>{item.title}</a>}
//                     description={`última vez hace ${format(item.date, dateFormat, { locale: es })}`}
//                     />
//             </List.Item>
//             )}
//         />
//     </Card>
// }

interface DashboardProps extends BaseViewProps {}

function Dashboard({ exposeSubMenu, setMainLoading, setPageInfo }: DashboardProps) {
    const { insights } = useSelector((state: any) => state)
    // const { insights, userInfo: { Blobs } } = useSelector((state: any) => state)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [showActive, setShowActive] = useState<boolean>(true)
    const { data, loading } = useQuery(CS_VIEW, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
    })
    const [blobs, setBlobs] = useState<any[]>([])
    // const history = useNavigate()

    useEffect(() => {
        if (data?.CountrysideView?.blobs?.length) {
            setBlobs(data.CountrysideView.blobs);
            (async () => {
                const resolve = await dispatch<Promise<any>>(
                    // @ts-ignore
                    updateCountryside({
                        Blobs: data.CountrysideView.blobs
                    })
                )
                console.log({ resolve })
            })()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])
    
    useEffect(() => {
        setPageInfo({
            title: 'Visualización de Campo',
            filter: true,
            menuItems: [
                <Menu.Item onClick={() => {
                    setMainLoading(true)
                    navigate('/configuration')
                }} icon={<PlusCircleOutlined />}>
                    Nuevo bloque
                </Menu.Item>
            ]
        })
        if (!!exposeSubMenu) {
            exposeSubMenu([
                { key: 'index', name: 'Resumen', icon: <HomeOutlined /> }
            ])
        }
        
        return () => {
            // Delete filter
            setPageInfo({
                title: 'Visualización de Campo',
                filter: false
            })
        }
    }, [setPageInfo, exposeSubMenu, setMainLoading, navigate])

    useEffect(() => {
        setMainLoading(loading)
    // eslint-disable-next-line no-sparse-arrays
    }, [,loading,setMainLoading])

    return (
        <div className="App">
            <div className="Dashboard">
                <div className="Wrapper">
                    <Row gutter={[16, 16]}>
                        {/* <Col xs={24} md={4}>
                            <FrequentlyActions key={`FrenquentlyActions`} insights={insights} />
                        </Col> */}
                        <Col xs={24} md={6}>
                            <Card>
                                <Statistic
                                    title={<>Número de Rayadas<h1 style={{ paddingBottom: 12 }}>{insights.produccion.total}</h1></>}
                                    value={' '}
                                    // precision={2}
                                    valueStyle={{ color: insights.produccion.budget < 0 ? '#cf1322' : '#3f8600' }}
                                    prefix={<>
                                        {insights.produccion.budget < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />} 0 / 0<br />
                                        <CheckOutlined className="disabledBlack" /> <span style={{ color: '#000' }}>- por recuperar</span>
                                    </>}
                                />
                                <AvanceCols color='yellow' data={insights.produccion} />
                            </Card>
                        </Col>
                        <Col xs={24} md={6}>
                            <Card>
                                <Statistic
                                    title={<>Producción<h1 style={{ paddingBottom: 12 }}>{insights.produccion.total} <small>{insights.produccion.um}</small></h1></>}
                                    value={insights.produccion.budget}
                                    precision={2}
                                    valueStyle={{ color: insights.produccion.budget < 0 ? '#cf1322' : '#3f8600' }}
                                    prefix={insights.produccion.budget < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                                    suffix={'%'}
                                />
                                <AvanceCols color='green' data={insights.produccion} />
                            </Card>
                        </Col>
                        <Col xs={24} md={6}>
                            <Card>
                                <Statistic
                                    title={<>Calidad de Rayado<h1 style={{ paddingBottom: 12 }}>{insights.qualityScratch.total} <small>{insights.qualityScratch.um}</small></h1></>}
                                    value={insights.qualityScratch.budget}
                                    precision={2}
                                    valueStyle={{ color: insights.qualityScratch.budget < 0 ? '#cf1322' : '#3f8600' }}
                                    prefix={insights.qualityScratch.budget < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                                    suffix={'%'}
                                />
                                <AvanceCols color='blue' data={insights.qualityScratch} />
                            </Card>
                        </Col>
                        <Col xs={24} md={6}>
                            <Card>
                                <Statistic
                                    title={<>Sangría Seca<h1 style={{ paddingBottom: 12 }}>{insights.sangriaSeca.total} <small>{insights.sangriaSeca.um}</small></h1></>}
                                    value={insights.sangriaSeca.budget}
                                    precision={2}
                                    valueStyle={{ color: insights.sangriaSeca.budget < 0 ? '#cf1322' : '#3f8600' }}
                                    prefix={insights.sangriaSeca.budget < 0 ? <ArrowDownOutlined /> : <ArrowUpOutlined />}
                                    suffix={'%'}
                                />
                                <AvanceCols color='red' data={insights.sangriaSeca} />
                            </Card>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} style={{
                        marginTop: 24
                    }}>
                        <Col span={12}>
                            <h1>Bloques</h1>
                            <h4>Navega por los bloques asociados a tu perfil.</h4>
                        </Col>
                        <Col span={12} style={{
                            textAlign: 'right',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                            alignItems: 'center'
                        }}>
                            <Checkbox style={{
                                marginRight: 12
                            }} defaultChecked value={showActive} onChange={(e: CheckboxChangeEvent) => {
                                setShowActive(e.target.checked)
                            }}>Mostrar bloques activos</Checkbox>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} className='BlobsContainer'>
                            {blobs.filter((b: any) => showActive ? b.enabled : true).map((blob: any) => <CardBlob key={`CardBlob${blob.name}`} {...blob} setMainLoading={setMainLoading} />)}
                    </Row>
                </div>
            </div>
        </div>
    )
}




export default Dashboard