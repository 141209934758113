import { Resource } from "i18next"

const en: Resource = {
    translation: {
        'Help': 'Ayuda',
        'Sign In': 'Inicio de Sesión',
        'powered by ALNUS SpA': 'Plataforma de Gestión de Datos para la Agricultura',
        'Complete your login info.': 'Completa tu información de inicio de sesión para ingresar a tu espacio privado en Rubberpl.us.',
        'Copyright': '© {{ year }} ALNUS SpA.',
        'Email address': 'Correo electrónico',
        'Enter your email address': 'Ingresa tu correo electrónico',
        'Password': 'Contraseña',
        'Enter your password': 'Ingresa tu contraseña',
        'Authorize this device': 'Autoriza este dispositivo',
        'Lost your password?': '¿Olvidaste tu contraseña?',
        'Login': 'Iniciar Sesión',
        'The data will be transferred through a secure SSL encrypted connection.': 'Los datos serán transferidos mediante una conexión segura SSL.',
        'Terms': 'Términos y Condiciones',
        'Privacy': 'Política de Privacidad',
        'Contact us': 'Contáctanos',
        'Recover your password': 'Recupera tu contraseña',
        'Complete your email to request a new password': 'Completa tu correo electrónico y haz clic en el botón recuperar, para enviar una solicitud de recuperación.',
        'Recover password': 'Recuperar',
        'Back to login': 'Volver a iniciar sesión',
        'Shortcuts': 'Atajos',
        'Configure your shortcuts': 'Configura tus atajos',
    }
}

export default en