import React, { useEffect, useState } from 'react'

import { Avatar, Badge, DatePicker, Menu, Select, Spin } from 'antd';
import { 
    UserOutlined,
    BellOutlined,
    FieldTimeOutlined,
    LeftOutlined
} from '@ant-design/icons';

import './index.scss'

import locale from 'antd/es/date-picker/locale/es_ES';
import { Link, useLocation, useNavigate } from 'react-router-dom'
// import { Prompt } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { setNavigation } from '../../Store/Actions';
import moment from 'moment';
import 'moment/locale/es';
import { subMonths } from 'date-fns';

const dateFormat = 'DD-MMM-YYYY'

type PageInfoState = {
    title: string
    filter: boolean
    period?: boolean
    customRender?: (current: moment.Moment) => JSX.Element
    menuItems?: JSX.Element[]
}  

export interface BaseViewProps {
    exposeSubMenu: React.Dispatch<React.SetStateAction<any[]>>
    setMainLoading: React.Dispatch<React.SetStateAction<boolean>>
    pageInfo: PageInfoState
    setPageInfo: React.Dispatch<React.SetStateAction<PageInfoState>>
    filters: {
        startDate: Date;
        endDate: Date;
    }
    period: number
}

const AppFrame = ({ children: ChildrenComponent, isMobile }: any) => {
    const { pathname } = useLocation()
    const { blockNavigation } = useSelector((state: any) => state) // insights
    let [, currentPath, currentSubPath] = pathname.split('/')

    const [pageInfo, setPageInfo] = useState<PageInfoState>({
        title: 'Panel General',
        filter: false
    })
    const [period,] = useState<number>(1)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [,setSubMenu] = useState([])
    const [loading, setLoading] = useState(true)

    const [dot, setDot] = useState(false)

    // case 'user:logout':
    //     dispatch(logout(null))
    //     return history.push('/')

    const [filters, setFilters] = useState({
        startDate: subMonths(new Date(), 1),
        endDate: new Date()
    })

    useEffect(() => {
        console.log({ pageInfo })
    }, [pageInfo])
    
    currentSubPath = currentSubPath === undefined ? 'index' : currentSubPath
    console.log(currentSubPath)

    // Get aleatory number between 1 and 100
    const getRandomInt = (min: number, max: number) => {
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    React.useEffect(() => {
        setTimeout(() => {
            setDot(true)
        }, 1000 * getRandomInt(15, 45))
    }, [])

    React.useEffect(() => {
        const current = pathname.split('/')
        if (current.includes('editMission') || current.includes('orderControl')) {
            dispatch(setNavigation(true))
        } else {
            if (blockNavigation) {
                dispatch(setNavigation(false))
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])
    return (
        <div className={`AppFrame${isMobile ? ' isMobile' : ' isDesktop'}`}>
            {/* <Prompt when={blockNavigation} message={'¿Estás seguro de perder los cambios? Recuerda publicar antes de abandonar esta página...'} /> */}
            <div className="Header-Wrapper">
                <div className={`Header Header-light Header-Primary`}>
                    <div className={'Header-Logo'}>
                        <Link to={`/dashboard`}>
                            <svg className="logo" x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48" overflow="inherit" xmlns="http://www.w3.org/2000/svg" style={{ float: 'left' }}>
                                <path d="M26.4708 27.7351C21.285 27.7351 17.0658 31.9542 17.0658 37.1401C17.0658 42.3266 21.285 46.5458 26.4708 46.5458C31.6574 46.5458 35.8758 42.3266 35.8758 37.1401C35.8758 31.9542 31.6574 27.7351 26.4708 27.7351ZM26.4708 48C20.4826 48 15.6116 43.1283 15.6116 37.1401C15.6116 31.1526 20.4826 26.2809 26.4708 26.2809C32.459 26.2809 37.33 31.1526 37.33 37.1401C37.33 43.1283 32.459 48 26.4708 48Z" fill="#AAA"/>
                                <path fillRule="evenodd" clipRule="evenodd" d="M36.2885 9.36387C33.8807 6.13799 30.4566 5.48686 26.7123 5.83988C24.3684 6.06056 22.0365 6.51356 19.6905 6.58191C15.1289 6.71461 11.895 4.03916 11.2951 -6.8778e-06H9.75C9.81799 0.495527 9.92523 0.989972 10.075 1.47787C11.3646 5.67301 14.1633 7.84566 18.618 8.08233C19.3404 8.12051 20.0722 8.11759 20.791 8.0467C23.0738 7.82092 25.3468 7.44173 27.6339 7.33085C30.5312 7.19088 33.1848 7.90199 35.0495 10.3637C38.8469 15.3768 36.9397 22.2987 31.0823 24.8225C26.7948 26.6701 22.2096 25.2435 19.3622 21.176C18.6743 20.194 18.6743 20.194 17.4651 20.9375C17.5309 21.0916 17.5702 21.2043 17.624 21.3083C20.1933 26.2175 26.0114 28.2811 31.3612 26.18C38.2867 23.461 40.7032 15.2776 36.2885 9.36387Z" fill="#FFF"/>
                                <path d="M26.4708 8.783C25.5761 10.0511 23.7895 12.6451 22.0208 15.5572C18.7793 20.8947 17.0658 24.8542 17.0658 27.008C17.0658 32.1945 21.285 36.4129 26.4708 36.4129C31.6574 36.4129 35.8758 32.1945 35.8758 27.008C35.8758 24.8542 34.1623 20.8947 30.9208 15.5572C29.1521 12.6451 27.3655 10.0511 26.4708 8.783ZM26.4708 37.8672C20.4826 37.8672 15.6116 32.9962 15.6116 27.008C15.6116 21.2415 25.4637 7.67669 25.8836 7.10226L26.4708 6.29769L27.0579 7.10226C27.4779 7.67669 37.33 21.2415 37.33 27.008C37.33 32.9962 32.459 37.8672 26.4708 37.8672Z" fill="#AAA"/>
                            </svg>
                        </Link>
                        <h2 className="logoText">rubberpl.us<small>Estás viendo <strong>{pageInfo.title}</strong></small></h2>
                    </div>
                    
                    <div className='Header-Filters'>
                        {/* Selector de Periodo */}
                        <Select disabled={loading} loading={loading} bordered={false} defaultValue="2021" style={{ width: 200 }} onChange={(value: string) => {
                            // console.log(`selected ${value}`);
                            }}>
                            <Select.OptGroup label="Periodos activos">
                                <Select.Option value="2021"><FieldTimeOutlined /> &nbsp;Periodo 2021</Select.Option>
                            </Select.OptGroup>
                        </Select>
                        {/* (opcional) Selector de Fechas dentro del periodo */}
                        {pageInfo.filter && <><span className='Divider'> | </span><DatePicker.RangePicker
                            /** @todo Limitar fechas dentro del periodo */
                            renderExtraFooter={() => <>
                                <strong>Fecha del Reporte</strong>
                                <p style={{ lineHeight: '18px' }}>Selecciona el rango de fechas para visualizar los datos en pantalla. Los días señalados en <strong style={{ color: '#FC0' }}>amarillo</strong> indican que no hay datos registrados.</p>
                            </>}
                            dropdownClassName='filtersPicker'
                            bordered={false}
                            disabled={loading}
                            key={`Filter-${currentPath}-${currentSubPath}-${!!pageInfo.customRender}`}
                            dateRender={(current) => {
                                if (pageInfo.customRender) {
                                    return pageInfo.customRender(current)
                                }
                                return <div className='ant-picker-cell-inner'> {current.date()} </div>
                                
                            }}
                            defaultValue={[
                                moment(filters.startDate, dateFormat),
                                moment(filters.endDate, dateFormat)
                            ]}
                            size='large'
                            locale={locale}
                            format={dateFormat}
                            onChange={(values, strings) => {
                                console.log({ values, strings })
                                const startDate = values?.[0]?.toDate()!
                                const endDate = values?.[1]?.toDate()!

                                setFilters({
                                    startDate,
                                    endDate
                                })
                            }}
                            /></>}
                    </div>
                    
                    <div className='Header-Menu'>
                        <Badge dot={dot}>
                            <Avatar size={32} style={{ backgroundColor: '#FFF', border: '1px solid #ccc', color: '#CCC' }} icon={<BellOutlined />} />
                        </Badge>
                        <Avatar size={42} style={{ backgroundColor: '#306465', marginLeft: 16 }} icon={<UserOutlined />} />
                    </div>
                </div>
                <div className='Header-Secondary'>
                    {(pageInfo.menuItems) && <Menu mode="horizontal" className='Header-GoBack'>
                        {pathname !== '/dashboard' && <Menu.Item onClick={() => navigate(-1)} icon={<LeftOutlined />}>Volver</Menu.Item>}
                    </Menu>}
                    
                    <Menu mode="horizontal">
                        {pageInfo.menuItems && pageInfo.menuItems.map((item, index) => {
                            return item
                        })}
                    </Menu>
                </div>
            </div>
            <Spin spinning={loading} delay={0} size='large' tip="Espera un momento, obteniendo información..." >
                {React.cloneElement(
                    ChildrenComponent,
                    {
                        exposeSubMenu: setSubMenu,
                        setMainLoading: setLoading,
                        pageInfo,
                        setPageInfo,
                        filters,
                        period
                    }
                )}
            </Spin>
        </div>
    )
}
export default AppFrame