/* eslint-disable no-sparse-arrays */
import { Button, Card, Checkbox, Col, DatePicker, Empty, Form, InputNumber, List, Menu, message, Rate, Row, Select, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react'

import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { HeatMapGrid } from 'react-grid-heatmap'

import {
    HighlightOutlined,
    PlusCircleOutlined,
    BgColorsOutlined,
    DeleteOutlined
} from '@ant-design/icons'

import locale from 'antd/es/date-picker/locale/es_ES';
import Wizard from './Wizard';
import { PRODUCTION_TASK } from "./connection";
import { useMutation } from "@apollo/client";
import { format } from 'date-fns';
import { BaseViewProps } from '../../../Components/AppFrame';

const TareaOption = ({ tarea, numero }: any) => {
    const [checked, setChecked] = useState(false)
     return <><Col span={checked ? 6 : 23}>
        <Checkbox onChange={(e) => setChecked(e.target.checked)}>
            <strong>Tarea {numero}</strong>: {tarea.lote}
        </Checkbox>
    </Col>
    {checked && <Col span={18}>
        <InputNumber min={1} max={100000} placeholder='Cantidad...' />
    </Col>}
    </>
}

const TareaCalidad = ({ tarea, numero }: any) => {
     return <><Col span={6}>
        <strong>Tarea {numero}</strong>: {tarea.lote}
    </Col>
    <Col span={18}>
        <Rate allowHalf />
    </Col>
    </>
}

interface ProductionDate {
    taskId: number
    date: Date
    production: number
    typeProductionId: 1 | 2
}

const Produccion = ({ currentBlob, setRegister, setMainLoading }: any) => {
    const focusRef = useRef<any>(null)
    
    // currentBlob.details.map((tarea: any, index: number) => <TareaProduccion tarea={tarea} numero={index + 1} />)
    const [form] = Form.useForm()
    const [lastDate, setLastDate] = useState<Date>()

    const navigate = useNavigate()

    const [productions, setProductions] = useState<ProductionDate[]>([])
    const [addProductionTask, { data, loading }] = useMutation(PRODUCTION_TASK)

    useEffect(() => {
        setRegister({
            disabled: false,
            name: 'Registrar',
            next: () => {
                form.submit()
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        setMainLoading(loading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading])

    useEffect(() => {
        if (data && data?.ProductionTask?.success) {
            navigate(`/blob/view/${currentBlob.id}`)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const renderItemProductionDate = (prod: ProductionDate, index: number) => {
        const TYPE_COAGULO = 1
        const TYPE_LATEX = 2

        const TagRender = new Map([
            [TYPE_COAGULO, <Tag color='volcano'><HighlightOutlined /> C</Tag>],
            [TYPE_LATEX, <Tag color='lime'><BgColorsOutlined /> L</Tag>]
        ])

        return <List.Item key={`ProductionDate${index}`} style={{
            display: 'flex',
        }}>
            <span>
                {TagRender.get(prod.typeProductionId)} Tarea {prod.taskId} - {currentBlob.details.find((d: any) => d.id === prod.taskId).lote} - {format(prod.date, 'yyyy-MM-dd')} - {prod.production} KG
            </span>
            <Button
            danger
            icon={<DeleteOutlined />}
            size={'small'}
            onClick={() => {
                setProductions(productions.filter((p) => p !== prod))
            }}
            /> 
            </List.Item>
    }

    const doForm = () => {
        console.log(form.getFieldsValue())
        let { taskId, production, date, typeProductionId } = form.getFieldsValue()
        if (!taskId || !productions || !date || !typeProductionId) {
            return false
        }

        date = new Date(date)

        setProductions([
            ...productions,
            { taskId, production, date, typeProductionId }
        ])

        form.setFieldsValue({
            taskId: null,
            production: null
        })

        setLastDate(date)
        focusRef.current?.focus()
    }

    return <>
    <Row>
        <h2>Ingreso de Producción<br />
        <small style={{
            fontWeight: 'normal'
        }}>Para ingresar la información de producción, debes seleccionar la tarea relacionada, la fecha de registro y la cantidad observada (P1).</small>
        </h2>
    </Row>
    <Row style={{
        width: '100%'
    }}>
        <Form
            layout='inline'
            size='large'
            style={{
                width: '100%',
                display: 'flex',
                alignItems: 'stretch'
            }}
            onFinish={(values) => {
                const variables = {
                    ProductionTaskInput: {
                        stripes: productions.map(prod => {
                            return {
                                date: format(prod.date, 'yyyy-MM-dd'),
                                taskId: prod.taskId,
                                inKg: prod.production,
                                typeId: prod.typeProductionId
                            }
                        }),
                        blobId: currentBlob.id
                    }
                }

                addProductionTask({
                    variables
                })
                    
            }}
            onFinishFailed={(err) => {
                console.log({ err })
            }}
            form={form}>

            <Form.Item label="Tipo" name="typeProductionId" style={{
                
            }}>
                <Select
                    placeholder="Seleccione tipo producción..."
                    style={{ minWidth: 150 }}
                    optionFilterProp="label"
                    autoFocus
                    showSearch
                    onInputKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            // @ts-ignore
                            document.querySelector('#taskId')?.click()
                            // @ts-ignore
                            document.querySelector('#taskId')?.focus()
                        }
                    }}
                    options={[
                        {
                            value: 1,
                            label: `Coágulo de Campo`,
                        },
                        {
                            value: 2,
                            label: 'Latex de Campo'
                        }
                    ]}
                    />
            </Form.Item>

            <Form.Item label="Tarea asociada" name="taskId" style={{
                
            }}>
                <Select
                    placeholder="Selecciona la tarea..."
                    style={{ minWidth: 200 }}
                    optionFilterProp="label"
                    ref={focusRef}
                    showSearch
                    onInputKeyDown={(e) => {
                        if (e.keyCode === 13) {
                            if (!lastDate) {
                                // @ts-ignore
                                document.querySelector('#date')?.click()
                                // @ts-ignore
                                document.querySelector('#date')?.focus()
                            } else {
                                // @ts-ignore
                                document.querySelector('#production')?.click()
                                // @ts-ignore
                                document.querySelector('#production')?.focus()
                            }
                        }
                    }}
                    options={currentBlob.details.map((task: any) => {
                        return {
                            value: task.id,
                            label: `Tarea ${task.id} - ${task.lote}`
                        }
                    })}
                    />
            </Form.Item>

            <Form.Item label="Fecha" name="date" style={{
                
            }}>
                <DatePicker
                    locale={locale}
                    onKeyDown={(e) => {
                        if (e.keyCode === 13 && form.getFieldsValue().date ) {
                            // @ts-ignore
                            document.querySelector('#production')?.click()
                            // @ts-ignore
                            document.querySelector('#production')?.focus()
                        }
                    }}
                    style={{
                        minWidth: 250
                    }} />
            </Form.Item>

            <Form.Item label="Producción (KG)" name="production" style={{
                
            }}>
                <InputNumber min={0} onKeyDown={(e) => {
                    if (e.keyCode === 13 && form.getFieldsValue().production ) {
                        doForm()
                    }
                }} />
            </Form.Item>

            <Form.Item>
                <Button type="primary" style={{ float: 'right' }} onClick={doForm}>+ Añadir</Button>
            </Form.Item>
        </Form>
    </Row>

    <Row style={{
        width: '100%'
    }}>
        <List
            size="default"
            footer={<><strong>Total de registros: </strong>{productions.length}</>}
            style={{ marginTop: 24, width: '100%' }}
            bordered
            dataSource={productions}
            renderItem={renderItemProductionDate}
        />
        
    </Row>

    </>
}

const Proyeccion = ({ currentBlob, setRegister, setMainLoading }: any) => {
    const [hasData] = useState<boolean>(false)
    const [wizard, setWizard] = useState<boolean>(true)

    if (!hasData && !wizard) {
        return <Empty style={{ width: '100%' }} description={<>No existen datos proyectados para <strong>{currentBlob?.name}</strong>.</>}>
            <Button type="primary" size='middle' onClick={e => setWizard(true)}>
                Asistente de Proyección
            </Button>
            &nbsp;&nbsp;<Button size='middle'>
                <Link  to={[
                    '/blob',
                    'view',
                    currentBlob.id
                ].join('/')}>Volver</Link>
            </Button>
        </Empty>
    }

    if (wizard) {
        return <Wizard {...currentBlob} setRegister={setRegister} setMainLoading={setMainLoading} />
    }

    
    return currentBlob.details.map((tarea: any, index: number) => <TareaOption tarea={tarea} numero={index + 1} />)
}

type BlobRegisterParams = {
    typeRegister: any
    blobId: any
}

function BlobRegister({ setMainLoading, setPageInfo }: BaseViewProps) {
    const { userInfo: { Blobs } } = useSelector((state: any) => state) // 
    const navigate = useNavigate()
    const { typeRegister, blobId } = useParams<BlobRegisterParams>()
    const [currentBlob, setCurrentBlob] = useState<any>({})
    const [register, setRegister] = useState<any>({
        disabled: true,
        title: 'Registrar',
        next: () => {
            //
        }
    })

    let [registerId] = typeRegister.split('-')
    registerId = Number(registerId)

    const listActions = new Map([
        [1, {
            h1: 'Datos de Proyección',
            h4: 'Revisa información de proyección de rayadas, calendario y recuperación.'
        }],
        [2, {
            h1: 'Registro de Producción',
            h4: 'Crea registros de información de producción P1/P2 para una(s) tarea(s).'
        }],
        [3, {
            h1: 'Registro de Evaluación',
            h4: 'Aplica una evaluación a un rayador.'
        }],
        [4, {
            h1: 'Registro de Sangría Seca',
            h4: 'Informa sobre sangría seca para algunos árboles en una tarea específica.'
        }],
    ])
    
    useEffect(() => {
        setMainLoading(false)
        // console.log({ Blobs, blobId })
        const BlobFounded = Blobs.find((b: any) => b.id === Number(blobId))
        if (!BlobFounded || !BlobFounded.enabled) {
            message.error('Oops, el bloque consultado no está activo aún, intenta más tarde.')
            navigate('/dashboard')
        } else {
            setCurrentBlob(BlobFounded)
            setPageInfo({
                title: `${listActions.get(registerId)?.h1 ?? 'Datos'} en ${BlobFounded?.name}`,
                filter: false,
                menuItems: [
                    <Menu.Item onClick={register.next} disabled={register.disabled} icon={<PlusCircleOutlined />}>
                        {register.name}
                    </Menu.Item>
                ]
            })
        }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [,register, registerId])

    
    const xLabels = new Array(24).fill(0).map((_, i) => `${i}`)
    const yLabels = [' ', ' ', ' ', ' ', ' ', ' ']
    const data = [[103,95,117,93,105,117,114,94,119,90,114,112,91,124,112,105,81,108,111,104,128,87,96,94],[90,122,91,93,121,99,83,129,84,117,121,116,87,96,94,104,109,88,117,87,87,92,81,82],[84,98,126,101,86,105,114,117,93,120,83,126,126,100,97,90,88,113,101,91,91,102,111,116],[129,126,97,121,120,98,103,105,129,113,124,122,127,126,118,80,120,108,87,114,88,80,126,95],[82,121,88,87,91,104,93,99,85,103,90,117,102,93,129,103,93,94,106,120,121,105,118,121],[84,103,88,84,98,115,93,116,81,87,102,120,80,112,92,98,90,96,81,81,81,89,87,99]]

    return (
        <div className="App">
            <div className="Dashboard BlobRegister">
                <div className="Wrapper">
                    {currentBlob?.insights && <><Row gutter={[16, 16]}>
                        {/* <Col xs={1} md={1}>
                            <Button size='large' onClick={() => navigate(`/blob/view/${blobId}`)} type='text' block icon={<LeftOutlined />} />
                        </Col>
                        <Col xs={14} md={20}>
                            <h1>{listActions.get(registerId)!.h1} en {currentBlob.name}</h1>
                            <h4>{listActions.get(registerId)!.h4}</h4>
                        </Col>
                        <Col xs={10} md={3} >
                            <Button size='large' danger block type='primary' onClick={register.next} disabled={register.disabled}>{register.name}</Button>
                        </Col> */}
                        <Col xs={24} md={24} offset={0}>
                            <Card>
                                <Row>
                                {registerId === 1 && <Proyeccion currentBlob={currentBlob} setRegister={setRegister} setMainLoading={setMainLoading} />}
                                {registerId === 2 && <Produccion currentBlob={currentBlob} setRegister={setRegister} setMainLoading={setMainLoading} />}
                                {registerId === 3 && <>
                                    {currentBlob.details.map((tarea: any, index: number) => <TareaCalidad tarea={tarea} numero={index + 1} />)}
                                </>}
                                {registerId === 4 && <>
                                    <h1>Seleccione las zonas de la tarea con sangría seca</h1><br /><br />
                                    <p>Puedes seleccionar más de una zona, y la ubicación es solamente referencial.</p>
                                    <HeatMapGrid
                                        data={data}
                                        xLabels={xLabels}
                                        yLabels={yLabels}
                                        squares
                                        style={{
                                            width: '100%'
                                        }}
                                        // Reder cell with tooltip
                                        cellRender={(x:any, y:any, value:any) => (
                                            <div title={`Zona(${x}, ${y})`}>{value}</div>
                                        )}
                                        xLabelsStyle={(index:any) => ({
                                            color: `rgb(0, 0, 0, 0)`,
                                            fontSize: '.8rem',
                                        })}
                                        yLabelsStyle={() => ({
                                            fontSize: '.7rem',
                                            textTransform: 'uppercase',
                                            color: 'rgba(0,0,0,0)'
                                        })}
                                        cellStyle={(_x:any, _y:any, ratio:any) => ({
                                            background: `rgb(244, 67, 54, 0.25)`,
                                            fontSize: '.8rem',
                                            color: `rgb(0, 0, 0, 0)`
                                        })}
                                        cellHeight='2rem'
                                        xLabelsPos='bottom'
                                        onClick={(x:any, y:any) => {
                                            // alert(`Referencia (${x}, ${y})`)
                                        }}
                                        yLabelsPos='right'
                                        
                                    />

                                </>}
                                </Row>
                            </Card>
                        </Col>
                    </Row></>}
                    
                       
                </div>
            </div>
        </div>
    )
}

export default BlobRegister
