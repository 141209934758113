import { gql } from "@apollo/client";

export const GET_PRODUCTION_TASK = gql`
query getProductionTask ($RIDProductionTaskInput: RIDProductionTaskInput!) {
    getProductionTask (input: $RIDProductionTaskInput) {
      success
      productions {
        taskId
        date
        inKg
        inKGProyectadas
        rayadas
        proyectadas
      }
      daysWithout
  }
}`

export const EDIT_BLOB = gql`
mutation CountrysideEditBlob ($CountrysideEditBlobInput: CountrysideEditBlobInput!) {
    CountrysideEditBlob (input: $CountrysideEditBlobInput) {
        success
        blob {
          id
          name
          description
          active
          createdAt
          updatedAt
        }
  }
}`