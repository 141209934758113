import { gql } from "@apollo/client";

export const CS_VIEW = gql`
query CountrysideView {
    CountrysideView {
        success
        blobs {
          id
          name
          description
          active
          enabled
          tasks
          details {
            id
            internalId
            periodId
            lote
            trees
            age
            stripeFrecuency
            workerId
            clonType
            stripeTypeId
            createdAt
          }
          createdAt
          updatedAt
        }
    }
}
`