import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Table,
  Tag
} from 'antd'
import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'

import {
  ProfileOutlined,
  DotChartOutlined,
  UserAddOutlined,
  DeleteOutlined
} from '@ant-design/icons'

import moment from 'moment'

import Column from 'antd/lib/table/Column'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CREATE_SCRATCHER, DELETE_SCRATCHER, GET_SCRATCHER_LIST } from './connection'
import { useForm } from 'antd/es/form/Form'
import { BaseViewProps } from '../../../Components/AppFrame'

moment.locale('es-es', {
  week: {
    dow: 1
  }
})

function BlobPeople({ setMainLoading, setPageInfo }: BaseViewProps) {
  const {
    userInfo: { Blobs }
  } = useSelector((state: any) => state) //
  const navigate = useNavigate()
  const { blobId } = useParams<any>()
  const [rayadores, setRayadores] = useState<any[]>([])

  const [modal, setModal] = useState<boolean>(false)
  const [form] = useForm()

  const [create, e_CreateScratcher] = useMutation(CREATE_SCRATCHER)
  const [erase, e_DeleteScratcher] = useMutation(DELETE_SCRATCHER)
  const [getData, { loading, data, refetch }] = useLazyQuery(GET_SCRATCHER_LIST, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'no-cache'
  })
  
  useEffect(() => {
    // console.log({ Blobs, blobId })
    const BlobFounded = Blobs.find((b: any) => b.id === Number(blobId))
    if (!BlobFounded || !BlobFounded.enabled) {
      message.error(
        'Oops, el bloque consultado no está activo aún, intenta más tarde.'
      )
      navigate('/dashboard')
    } else {
      getData({
        variables: {
          PeopleListScratcherInput: {
            blobId: Number(blobId)
          }
        }
      })
      setPageInfo({
        title: `Listado de Rayadores en ${BlobFounded?.name}`,
        filter: false,
        menuItems: [
          <Menu.Item onClick={() => setModal(true)} icon={<UserAddOutlined />}>
            Nuevo rayador
          </Menu.Item>
        ]
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (data?.PeopleListScratcher?.success && data?.PeopleListScratcher?.scratchers?.length > 0) {
      setRayadores(data.PeopleListScratcher.scratchers)
    }
  }, [data])

  useEffect(() => {
    setModal(false)
    form.resetFields()
    if (refetch) {
      refetch()
      message.success('Listado de Rayadores actualizado correctamente.');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [e_CreateScratcher.data, e_DeleteScratcher.data])

  useEffect(() => {
    setMainLoading(loading || e_CreateScratcher.loading)
  }, [loading, e_CreateScratcher.loading, setMainLoading])

  return (
    <div className='App'>
      <Modal
        title="Registro de nuevo Rayador"
        visible={modal}
        className='modalWizard'
        width={1000}
        onOk={() => {
          // setConfirmLoading(true);
          // setTimeout(() => {
          //   setModal(false);
          //   setConfirmLoading(false);
          // }, 2000);
          form.submit()
        }}
        confirmLoading={e_CreateScratcher.loading}
        cancelButtonProps={{
          disabled: e_CreateScratcher.loading
        }}
        onCancel={() => setModal(false)}
      >
        <Row gutter={[32, 16]}>
          <Col span={8} className='leftWizard'>
            <h4>Ficha del Rayador</h4>
            <p>Debes completar los campos obligatorios para la creación de un nuevo rayador/a. Los campos que tienen una marca de color rojo, son obligatorios.</p>
            <h4>Integración</h4>
            <p>Si necesitas añadir más información, puedes subir archivos asociados a la ficha del rayador/a que estarán disponibles cuando ejecutes una evaluación o al visualizar su registro individual.</p>
          </Col>
          <Col span={16}>
            <Form form={form} layout='vertical' onFinish={({ user: { name, lastName, ingressDate, cc, age, gender } }) => {

                create({
                  variables: {
                    PeopleCreateScratcherInput: {
                      blobId: Number(blobId),
                      name: name.trim(),
                      lastName: lastName.trim(),
                      ingressAt: ingressDate.format('YYYY-MM-DD'),
                      identityCard: `${cc.trim()}`,
                      age: age,
                      gender: Number(gender)
                    }
                  }
                })
              }} name="nest-messages">
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item name={['user', 'name']} label="Nombre" rules={[{ required: true }]}>
                      <Input tabIndex={1} />
                    </Form.Item>
                    <Form.Item name={['user', 'ingressDate']} label="Fecha de Ingreso" rules={[{ required: true }]}>
                      <DatePicker tabIndex={3} style={{ minWidth: '100%' }} />
                    </Form.Item>
                    <Form.Item name={['user', 'age']} label="Edad" rules={[{ type: 'number', min: 0, max: 99 }]}>
                      <InputNumber tabIndex={5} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name={['user', 'lastName']} label="Apellidos" rules={[{ required: true }]}>
                      <Input tabIndex={2} />
                    </Form.Item>
                    <Form.Item name={['user', 'cc']} label="Cédula de Ciudadania (CC):" rules={[{ required: true }]}>
                      <Input tabIndex={4} />
                    </Form.Item>
                    <Form.Item name={['user', 'gender']} label="Género" rules={[{ required: true }]}>
                      <Select
                        placeholder="Selecciona una opción"
                        allowClear
                        tabIndex={6}
                      >
                        <Select.Option value={0}>Hombre</Select.Option>
                        <Select.Option value={1}>Mujer</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
          </Col>
        </Row>
      </Modal>
      <div className='Dashboard BlobPeople'>
        <div className='Wrapper'>
          <Row gutter={[16, 16]} className='TasksDetails'>
            <Col xs={24} md={24}>
              <Table
                  dataSource={[...rayadores, ...rayadores, ...rayadores]}
                  pagination={{
                    defaultPageSize: 60,
                    showSizeChanger: false
                  }}
                  style={{ marginTop: 32 }}
                  size='small'
                >
                  {/* <Column title='N°' dataIndex='id' key='id' /> */}
                  <Column dataIndex='mix' key='mix' render={(_, record: any, index) => {
                    return new Map([
                      [0, <Tag color='green'>9.3</Tag>],
                      [1, <Tag color='gold'>8.4</Tag>],
                      [2, <Tag color='red'>6.9</Tag>],
                    ]).get(index)
                  }} />
                  <Column title='Nombre' dataIndex='name' key='name' />
                  <Column title='Apellidos' dataIndex='lastName' key='lastName' />
                  <Column title='Cuchilla' filters={[
                    { text: 'Malaya', value: 'Malaya' },
                    { text: 'Tailandesa', value: 'Tailandesa' }
                  ]} dataIndex='knife' key='knife' render={(_, record: any) => {
                    return 'Malaya'
                    // return record.knife ? <Tag color='green'>Si</Tag> : <Tag color='red'>No</Tag>
                  }} />
                  <Column title='F. de Ingreso' dataIndex='ingressAt' key='ingressAt' render={(text) => moment(text).format('DD/MM/YYYY')} />
                  <Column
                    title='Tareas'
                    dataIndex='tasks'
                    key='tasks'
                    render={(_, record: any) => {
                      if (!record?.Tasks?.length) {
                        return <i>No hay tareas asignadas.</i>
                      }
                      return <>
                        {record?.Tasks?.map(((task: any) => {
                          return <Tag>Tarea {task?.Task?.internalId} ({task?.Task?.trees}a)</Tag>
                        }))}
                      </>
                    }}
                  />
                  <Column title='Última evaluación' render={(_, record: any, index) => {
                    return `hace ${index + 2} días`
                  }} />
                  <Column
                    title='Opciones'
                    width={300}
                    fixed='right'
                    render={(text, record: any) => {
                      return (
                        <>
                          <Button type='primary' icon={<DotChartOutlined />}>Evaluar</Button>
                          &nbsp;
                          <Button icon={<ProfileOutlined />}>Hoja de Vida</Button>
                          &nbsp;
                          <Button onClick={() => {
                            Modal.confirm({
                              title: <>¿Estás seguro de eliminar al rayador <strong>{record.name} {record.lastName}</strong>?</>,
                              icon: <DeleteOutlined />,
                              content: `Eliminar este rayador no eliminará los datos de producción, pero el rayador no estará disponible en ninguna tarea.`,
                              okText: 'Sí',
                              okType: 'danger',
                              cancelText: 'No',
                              onOk() {
                                return erase({
                                  variables: {
                                    PeopleDeleteScratcherInput: {
                                      scratcherId: record.id
                                    }
                                  }
                                })
                              },
                              onCancel() {
                                message.info('No se han realizado cambios.');
                              },
                            });
                          }} danger icon={<DeleteOutlined />}></Button>
                        </>
                      )
                    }}
                  />
                </Table>
              {/* <List
                itemLayout='horizontal'
                dataSource={rayadores}
                renderItem={(item: any) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Badge
                          count={item.point}
                          style={{ backgroundColor: item.color }}
                        >
                          <Avatar shape='square' icon={<UserOutlined />} />
                        </Badge>
                      }
                      title={<a href='/#'>{item.title}</a>}
                      description={`${item.tests} evaluaciones aplicadas`}
                    />
                  </List.Item>
                )}
              /> */}
            </Col>
            <Col xs={24} md={18}>
              
            </Col>
          </Row>

          {/*<Modal title='Gráfico producción proyectada' visible={isModal} onOk={() => setModal(false)} onCancel={() => setModal(false)}>
                            <Line type='line' data={dataAxis} options={options} />
                        </Modal>*/}

        </div>
      </div>
    </div>
  )
}

export default BlobPeople
