import React from 'react'

export default function Leaf (props: any) {
    return <svg {...props} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 25.3501C25.1598 25.3501 26.1 24.4099 26.1 23.2501C26.1 22.0903 25.1598 21.1501 24 21.1501C22.8402 21.1501 21.9 22.0903 21.9 23.2501C21.9 24.4099 22.8402 25.3501 24 25.3501Z" fill="#699635"/>
    <path d="M2.85 30.0002C-1.575 37.3502 6.075 39.6002 9.45 37.1252C6.9 40.8752 9.375 47.9252 16.725 43.6502C24.9 38.8502 22.65 25.2752 23.1 23.7002C21.75 23.9252 7.575 22.1252 2.85 30.0002" fill="#7BB246"/>
    <path d="M9.45 37.1251C10.5 35.9251 11.625 34.8001 12.675 33.6001L16.05 30.2251C17.175 29.1001 18.3 27.9751 19.5 26.9251L21.225 25.2751L23.025 23.7001L21.45 25.5001L19.8 27.2251L16.425 30.6001C15.3 31.7251 14.175 32.8501 12.975 33.9001C11.85 35.0251 10.725 36.0751 9.45 37.1251" fill="#699635"/>
    <path d="M45.15 16.5C49.575 9.15005 41.925 6.90005 38.55 9.37505C41.1 5.62505 38.625 -1.42495 31.275 2.85005C23.1 7.65005 25.35 21.225 24.9 22.8001C26.25 22.5751 40.425 24.3751 45.15 16.5Z" fill="#7BB246"/>
    <path d="M38.55 9.375C37.5 10.575 36.375 11.7 35.325 12.9L31.95 16.275C30.825 17.4 29.7 18.525 28.5 19.575L26.775 21.225L24.975 22.8L26.55 21L28.2 19.275L31.575 15.9C32.7 14.775 33.825 13.65 35.025 12.6C36.15 11.475 37.275 10.425 38.55 9.375" fill="#699635"/>
    <path d="M16.725 2.85015C9.225 -1.49985 6.975 6.00015 9.45 9.37515C5.7 6.90015 -1.5 9.30015 2.85 16.5002C7.725 24.5251 21.525 22.3501 23.1 22.7251C22.875 21.4501 24.75 7.50015 16.725 2.85015" fill="#83BF4F"/>
    <path d="M9.45 9.375C10.65 10.425 11.85 11.475 12.975 12.525C14.175 13.575 15.3 14.7 16.425 15.825L19.8 19.2L21.45 20.925L23.025 22.725L21.225 21.15L19.5 19.575C18.3 18.525 17.175 17.4 16.05 16.275L12.675 12.9C11.625 11.7 10.5 10.575 9.45 9.375" fill="#699635"/>
    <path d="M31.275 43.65C38.775 48 41.025 40.5 38.55 37.125C42.375 39.6 49.5 37.2 45.15 30C40.275 21.975 26.475 24.15 24.9 23.775C25.125 25.05 23.25 39 31.275 43.65Z" fill="#83BF4F"/>
    <path d="M38.55 37.1251C37.35 36.0751 36.15 35.0251 35.025 33.9751C33.825 32.9251 32.7 31.8001 31.575 30.6751L28.2 27.3001L26.55 25.5001L24.975 23.7001L26.775 25.2751L28.5 26.9251C29.7 27.9751 30.825 29.1001 31.95 30.2251L35.325 33.6001C36.375 34.8001 37.5 35.9251 38.55 37.1251" fill="#699635"/>
    <path d="M24 27.6001L25.875 44.6251C25.95 45.6001 25.2 46.4251 24.225 46.5001C23.175 46.5751 22.275 45.9001 22.125 44.9251V44.5501L24 27.6001Z" fill="#699635"/>
</svg>
}
