import { gql } from "@apollo/client";

export const CREATE_BLOB = gql`
mutation CountrysideCreateBlob ($CountrysideCreateBlobInput: CountrysideCreateBlobInput!) {
    CountrysideCreateBlob (input: $CountrysideCreateBlobInput) {
        success
        blob {
          id
          name
          description
          active
          createdAt
          updatedAt
        }
  }
}`