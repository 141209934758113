import { gql } from "@apollo/client";

export const GET_SCRATCHER_LIST = gql`
query PeopleListScratcher ($PeopleListScratcherInput: PeopleListScratcherInput!) {
    PeopleListScratcher (input: $PeopleListScratcherInput) {
      success
      scratchers {
        id
        blobId
        name
        lastName
        ingressAt
        identityCard
        age
        gender
        email
        enabled
        typeShiftId
        Tasks {
          id
          Task {
            id
            internalId
            trees
          }
        }
      }
  }
}`

export const CREATE_SCRATCHER = gql`
mutation PeopleCreateScratcher ($PeopleCreateScratcherInput: PeopleCreateScratcherInput!) {
    PeopleCreateScratcher (input: $PeopleCreateScratcherInput) {
      success
      scratcher {
        id
        blobId
        name
        lastName
        ingressAt
        identityCard
        age
        gender
        email
        enabled
        typeShiftId
      }
  }
}`

export const ASSIGN_SCRATCHER = gql`
mutation PeopleAssignToTask ($PeopleAssignToTaskInput: PeopleAssignToTaskInput!) {
    PeopleAssignToTask (input: $PeopleAssignToTaskInput) {
      success
      updatedTask {
        id
        internalId
        periodId
        lote
        trees
        age
        stripeFrecuency
        workerId
        clonType
        stripeTypeId
        createdAt
      }
  }
}`

export const DELETE_SCRATCHER = gql`
mutation PeopleDeleteScratcher ($PeopleDeleteScratcherInput: PeopleDeleteScratcherInput!) {
    PeopleDeleteScratcher (input: $PeopleDeleteScratcherInput) {
      success
      deletedScratcher {
        id
        blobId
        name
        lastName
        ingressAt
        identityCard
        age
        gender
        email
        enabled
        typeShiftId
      }
  }
}`